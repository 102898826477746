import React from "react";
import { useTranslation } from "react-i18next";


const T06AboutUS = ({ type, data }) => {
    const { t } = useTranslation();

    return (
        <div className="row mx-auto mt-5 align-items-center" style={{width: 'calc(100% - var(--px-meduim))'}}>
            <div className="col-12" >
                <div data-aos='fade-up' className={`col-12 flex-column d-flex justify-content-center align-items-center `}>
                    {type !== 'broker' && (<>
                        <span className="section-prefix tag" data-aos="fade-up" data-aos-duration="1500">{t("AboutUS1")}</span>
                        <h2 className='sec-title text-center border-lg-start' data-aos="fade-up" data-aos-duration="1500">
                            {data.title1}
                        </h2>
                    </>)}
                    <p className={`sec-desc text-center`}>{data.content}</p>
                </div>
            </div>
           
        </div>
    );
}

export default T06AboutUS;
