import React from "react";
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import QRCode from "react-qr-code";
import { Alert } from "@mui/material";
import { useTranslation } from "react-i18next";
import { resolveRerBordersValue } from "../helpers";

const publicUrl = process.env.REACT_APP_URL + "/";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const REGAPropertyInfo = ({ onOK, property }) => {
  const [expanded, setExpanded] = React.useState('panel1');
  const { nhc } = property;
  const { t } = useTranslation();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  function getFormatedPrice(price) {
    let value = price?.replace(/[,]+/g, "")?.replace(/[.]{1}[0]+/,'');
    return value?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  }

  return (
    <div className='custom-card ad-constraints'>
      <div className='card-header'>
        <h4>{t("ReviewRealEstateADInformation")}</h4>
        <h6>{t("ReviewRealEstateADInformationDesc")}</h6>
      </div>
      <div className='card-body'>
        <div>
          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
              <Typography>{t("AdvertisingLicenseInformation")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="row m-0">
              
                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("RealEstateADLicenseNumber")}</span>
                  <span>{nhc?.adLicenseNumber}</span>
                </div>
                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("AdvertisementReleaseDate")}</span>
                  <span>{nhc?.creationDate}</span>
                </div>
                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("AdvertisementLicenseExpiryDate")}</span>
                  <span>{nhc?.endDate}</span>
                </div>
                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("PropertyType")}</span>
                  <span>{nhc?.propertyType}</span>
                </div>
                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("PropertyUsages")}</span>
                  <span>
                    {nhc?.propertyUsages?.length > 0 ?
                      nhc?.propertyUsages?.map((item, index) => <span key={item}>{item + (index < nhc?.propertyUsages?.length - 1 ? ', ' : '')}</span>) : ""
                    }
                  </span>
                </div>
                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("PropertyArea")}</span>
                  <span style={{ direction: 'rtl' }}>{nhc?.propertyArea?.replace(/[.]{1}[0]+/,'') + ` ${t("M2")}`}</span>
                </div>
                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("ADPurpose")}</span>
                  <span>{nhc?.advertisementType}</span>
                </div>
                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("AdvertiserName")}</span>
                  <span>{nhc?.advertiserName}</span>
                </div>
                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("AdvertiserIDNumber")}</span>
                  <span>{nhc?.advertiserId}</span>
                </div>
                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("AdvertiserContactNumber")}</span>
                  <span>{nhc?.phoneNumber}</span>
                </div>
                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("TitleDeedNumber")}</span>
                  <span>{nhc?.deedNumber}</span>
                </div>
              
                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("FalLicense")}</span>
                  <span>{nhc?.brokerageAndMarketingLicenseNumber}</span>
                </div>
           
                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>
                    {nhc?.propertyType === "أرض" ? t("MeterPrice") : t("UnitPrice")}</span>
                  <span>{nhc?.propertyPrice ? getFormatedPrice(nhc?.propertyPrice + "") + ` ${t("SAR")}  ` : ''}</span>
                </div>
             
                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("AdvertisingChannels")}</span>
                  <span>
                    {nhc?.channels?.length > 0 ?
                      nhc?.channels?.map((item, index) => <span key={item}>{item + (index < nhc?.channels?.length - 1 ? ', ' : '')}</span>) : ""
                    }
                  </span>
                </div>
                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("QRCode")}</span>
                  <span>
                    {nhc?.adLicenseURL ? <>
                      <a href={nhc?.adLicenseURL} rel="noreferrer" target="_blank">
                        <div style={{ height: "auto", margin: "0", maxWidth: 95, width: "100%" }}>
                          <QRCode
                            size={256}
                            style={{
                              height: "auto",
                              maxWidth: "100%",
                              width: "100%"
                            }}
                            value={nhc?.adLicenseURL}
                            viewBox={`0 0 256 256`}
                          />
                        </div>
                      </a>
                    </> : t("NotAvailable")}
                  </span>
                </div>
                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("AdvertiseLicenseSource")}</span>
                  <span>
                    {nhc?.adSource === "الهيئة العامة للعقار" ?
                      <img src={publicUrl + 'assets/img/logo/rega-logo.png'}
                        style={{
                          width: '155px',
                          height: '43px'
                        }}
                        alt={t("GeneralAuthorityForRealEstate")} />
                      : nhc?.adSource}
                  </span>
                </div>
                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("AdvertiseLicenseLink")}</span>
                  <span>{nhc?.adLicenseURL ? <a href={nhc?.adLicenseURL} rel="noreferrer" target="_blank" style={{ color: 'var(--main-color-one)', textDecoration: 'underline' }}>{t("ClickHere")}</a> : t("NotAvailable")}</span>
                </div>
                {nhc?.propertyType === "أرض" &&
                  <div className="rega-info col-12 col-md-6 col-lg-4">
                    <span>{t("TotalLandSalePrice")}</span>
                    <span>{nhc?.landTotalPrice ? getFormatedPrice(nhc?.landTotalPrice + "") + ` ${t("SAR")}  ` : t("NotAvailable")}</span>
                  </div>
                }
                {nhc?.propertyType === "أرض" &&
                  <div className="rega-info col-12 col-md-6 col-lg-4">
                    <span>{t("TotalAnnualRentOfLand")}</span>
                    <span>{nhc?.LandTotalAnnualRent ? getFormatedPrice(nhc?.LandTotalAnnualRent + "") + ` ${t("SAR")} ` : t("NotAvailable")}</span>
                  </div>
                }
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
            <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
              <Typography>{t("PropertyInformation")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="row m-0">
                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("StreetWidth")}</span>
                  <span style={{ direction: 'rtl' }}>{nhc?.streetWidth?.replace(/[.]{1}[0]+/,'') + ` ${t("M2")}`}</span>
                </div>
                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("PlanNumber")}</span>
                  <span>{nhc?.planNumber || t("Nothing")}</span>
                </div>
                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("PropertyFace")}</span>
                  <span>{nhc?.propertyFace || t("NotAvailable")}</span>
                </div>
                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("PropertyUtilities")}</span>
                  <span>
                    {nhc?.propertyUtilities?.length > 0 ?
                      nhc?.propertyUtilities?.map((item, index) => <span key={item}>{item + (index < nhc?.propertyUtilities?.length - 1 ? ', ' : '')}</span>) : ""
                    }
                  </span>
                </div>
                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("PropertyAge")}</span>
                  <span>{nhc?.propertyAge || t("NotAvailable")}</span>
                </div>
                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("NumberOfRooms")}</span>
                  <span>{nhc?.numberOfRooms || t("NotAvailable")}</span>
                </div>
                
                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("ComplianceWithTheSaudiBuildingCode")}</span>
                  <span>{nhc?.complianceWithTheSaudiBuildingCode || t("Nothing")}</span>
                </div>
               
                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("GuaranteesAndTheirDuration")}</span>
                  <span>{nhc?.guaranteesAndTheirDuration || t("Nothing")}</span>
                </div>
                
                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("ObligationsOnTheProperty")}</span>
                  <span>{nhc?.obligationsOnTheProperty || t("Nothing")}</span>
                </div>
                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("IsConstrained")}</span>
                  <span>{nhc?.isConstrained === "0" ? t("No") : nhc?.isConstrained === "1" ? t("Yes") : nhc?.isConstrained}</span>
                </div>
                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("IsPawned")}</span>
                  <span>{nhc?.isPawned === "0" ? t("No") : nhc?.isPawned === "1" ? t("Yes") : nhc?.isPawned}</span>
                </div>
                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("IsHalted")}</span>
                  <span>{nhc?.isHalted === "0" ? t("No") : nhc?.isHalted === "1" ? t("Yes") : nhc?.isHalted}</span>
                </div>
                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("IsTestment")}</span>
                  <span>{nhc?.isTestment === "0" ? t("No") : nhc?.isTestment === "1" ? t("Yes") : nhc?.isTestment}</span>
                </div>
                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("LandNumber")}</span>
                  <span>{nhc?.landNumber || t("NotAvailable")}</span>
                </div>
                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("TitleDeedTypeBenefitDeedType")}</span>
                  <span>{nhc?.titleDeedTypeName}</span>
                </div>
                <div className="rega-info col-12 col-md-6">
                  <span>{t("DescriptionOfThePropertyLocationAccordingToTheDeed")}</span>
                  <span>{nhc?.locationDescriptionOnMOJDeed || t("NotAvailable")}</span>
                </div>
                <div className="rega-info col-12 col-md-6">
                  <span>{t("RERConstraints")}</span>
                  <span>{nhc?.rerConstraints.length > 0 ? nhc?.rerConstraints?.map(item => item + ', ') : t("Nothing")}</span>
                </div>
                
                {/* <div className="rega-info col-12">
                  <span>حدود وأطوال العقار</span>
                  <span>{nhc?.theBordersAndLengthsOfTheProperty || t("Nothing")}</span>
                </div> */}

                <div className="rega-info col-12">
                  <span>{t("Notes1")}</span>
                  <span>{nhc?.Notes || t("Nothing")}</span>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
              <Typography>{t("PropertyLocation")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="row m-0">

                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("Region")}</span>
                  <span>{nhc?.location?.region}</span>
                </div>
                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("City")}</span>
                  <span>{nhc?.location?.city}</span>
                </div>
                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("Neighborhood")}</span>
                  <span>{nhc?.location?.district}</span>
                </div>
                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("Street")}</span>
                  <span>{nhc?.location?.street || t("NotAvailable")}</span>
                </div>
                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("ZipCode")}</span>
                  <span>{nhc?.location?.postalCode}</span>
                </div>
                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("BuildingNumber")}</span>
                  <span>{nhc?.location?.buildingNumber}</span>
                </div>
                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("AdditionalNumber")}</span>
                  <span>{nhc?.location?.additionalNumber}</span>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
            <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
              <Typography>{t("PropertyBoundariesAndLengthsFromTheMinistryOfJustice")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="row m-0">

                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("NorthernBoundaryType")}</span>
                  <span>{nhc?.borders?.northLimitName || t("NotAvailable")}</span>
                </div>
                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("NorthernBoundaryDescription")}</span>
                  <span>{nhc?.borders?.northLimitDescription || t("NotAvailable")}</span>
                </div>
                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("NorthernBoundaryLength")}</span>
                  <span>{nhc?.borders?.northLimitLengthChar || t("NotAvailable")}</span>
                </div>

                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("EasternBoundaryType")}</span>
                  <span>{nhc?.borders?.eastLimitName || t("NotAvailable")}</span>
                </div>
                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("EasternBoundaryDescription")}</span>
                  <span>{nhc?.borders?.eastLimitDescription || t("NotAvailable")}</span>
                </div>
                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("EasternBoundaryLength")}</span>
                  <span>{nhc?.borders?.eastLimitLengthChar || t("NotAvailable")}</span>
                </div>

                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("WesternBoundaryType")}</span>
                  <span>{nhc?.borders?.westLimitName || t("NotAvailable")}</span>
                </div>
                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("WesternBoundaryDescription")}</span>
                  <span>{nhc?.borders?.westLimitDescription || t("NotAvailable")}</span>
                </div>
                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("WesternBoundaryLength")}</span>
                  <span>{nhc?.borders?.westLimitLengthChar || t("NotAvailable")}</span>
                </div>

                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("SouthernBoundaryType")}</span>
                  <span>{nhc?.borders?.southLimitName || t("NotAvailable")}</span>
                </div>
                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("SouthernBoundaryDescription")}</span>
                  <span>{nhc?.borders?.southLimitDescription || t("NotAvailable")}</span>
                </div>
                <div className="rega-info col-12 col-md-6 col-lg-4">
                  <span>{t("SouthernBoundaryLength")}</span>
                  <span>{nhc?.borders?.southLimitLengthChar || t("NotAvailable")}</span>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          {nhc?.rerBorders.length > 0 && (
            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
              <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                <Typography>{t("BoundariesAndLengthsOfThePropertyFromTheRealEstateRegistry")}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="row m-0">
                  {nhc?.rerBorders?.map((item, index) => (
                    <React.Fragment key={index}>
                      <div className="rega-info col-12 col-md-6 col-lg-4">
                        <span>{t("Direction")}</span>
                        <span>{item?.direction ? resolveRerBordersValue(item?.direction) : t("NotAvailable")}</span>
                      </div>
                      <div className="rega-info col-12 col-md-6 col-lg-4">
                        <span>{t("BorderType")}</span>
                        <span>{item?.type ? resolveRerBordersValue(item?.type) : t("NotAvailable")}</span>
                      </div>
                      <div className="rega-info col-12 col-md-6 col-lg-4">
                        <span>{t("BorderLength")}</span>
                        <span>{item?.length || t("NotAvailable")}</span>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              </AccordionDetails>
            </Accordion>
          )}
      
          <Alert
            severity='warning'
            className='mt-4'
            style={{ width: "fit-content" }}>
            {t("PleaseNoteThatYourADWillBeAutomaticallyDeletedOn", { date: nhc?.endDate })}
          </Alert>
        </div>
      </div>
      <div className='card-footer'>
        <button
          className='primary-btn px-5'
          onClick={(e) => {
            onOK();
          }}>
          {t("Next")}
        </button>
      </div>
    </div>
  );
}

export default REGAPropertyInfo;
