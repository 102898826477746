import { Close } from '@mui/icons-material';
import { Alert, Box, Dialog, DialogContent, DialogTitle, FormControl, FormHelperText, InputAdornment, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import LoadingButton from '../../../../components/global-components/loading-btn';
import { preventArabicString } from '../../../../helpers';
import { checkDomainAvailabilityWithAWS } from '../../../../api/userApi';
import { useAuth } from '../../../../helpers/context';

export default function ChangeDomainDialog({ open, setOpen, oldDomain, onOK, setDomainNameWithExt, setDomainWithoutExt }) {
    const { t, i18n } = useTranslation();
    const [domain, setDomain] = useState("");
    const [errors, setErrors] = useState({});
    const [isSubmittingData, setIsSubmittingData] = useState(false);
    const { token } = useAuth();
    
    useEffect(() => {
        if (oldDomain) {
            setDomain(oldDomain)
        }
    }, [oldDomain]);

    const formIsValid = () => {
        const _errors = {};
        if (!domain) _errors.domain = t("EnterDomainName");
        setErrors(_errors);
        return Object.keys(_errors).length === 0;
    }
    
    const handleSubmit = async (event) => {
        event.preventDefault();
    
        if (!formIsValid()) return;
        setIsSubmittingData(true);
        checkDomainAvailabilityWithAWS({
            domainNameWithoutExtension: domain,
            userId: token,
            free: '1',
            language: i18n.language === "ar" ? "0" : "1"
        }).then((_items) => {
            if (_items.resCode === 0) {
                setDomainWithoutExt(_items.response?.[0]?.domain?.substring(0, _items.response?.[0]?.domain?.indexOf('.')));
                setDomainNameWithExt(_items.response?.[0]?.domain);
  
                setOpen(false);
                onOK(_items.response?.[0]?.domain, _items.response?.[0]?.domain?.substring(0, _items.response?.[0]?.domain?.indexOf('.')));
                
            } else {
                setErrors({ domain: _items.resStr });
            }
        }).finally(() => {
            setIsSubmittingData(false);
        });
          
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"xs"}
            open={open}
            className="custom-dialog model"
            onClose={() => { setOpen(false); onOK(); }}>
            <DialogTitle>
                <div className="dialog-head">
                    <div>
                        <h2>{t("ChangeDomain")}</h2>
                    </div>
                    <div className="dialog-actions">
                        <span onClick={() => { setOpen(false); onOK(); }} style={{ cursor: 'pointer' }}>
                            <Close />
                        </span>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '15px', marginTop: '15px' }}>
                    <FormControl error={Boolean(errors.domain)} style={{ direction: 'ltr' }}>
                        <TextField
                            sx={{ width: "100%" }}
                            label={t("Domain")}
                            style={{ direction: 'ltr' }}
                            size='small'
                            type='text'
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => {
                                setDomain(preventArabicString(e.currentTarget.value))
                            }}
                            error={Boolean(errors.domain)}
                            InputProps={i18n.language === "ar" ? {
                                startAdornment: (
                                    <InputAdornment sx={{ '& p': { textAlign: 'right', direction: 'ltr' } }} position='start'>.amakkn.com</InputAdornment>
                                )
                            } : {
                                endAdornment: (
                                    <InputAdornment sx={{ '& p': { textAlign: 'right', direction: 'ltr' } }} position='start'>.amakkn.com</InputAdornment>
                                )
                            }}
                            value={domain}
                        />
                    </FormControl>
                    <LoadingButton
                        loading={isSubmittingData}
                        label={t("Change")}
                        style={{ width: 'fit-content' }}
                        handleClick={handleSubmit}
                    />
                </div>
                {errors.domain &&
                    <div className="col-12 px-0 mt-2">
                        <FormHelperText component={Box}>
                            <Alert severity="error" className="custom-alert">
                                {errors.domain}
                            </Alert>
                        </FormHelperText>
                    </div>
                }
            </DialogContent>
        </Dialog>
    );
}
