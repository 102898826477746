import React, { useEffect, useState } from "react";
import { Buildings, Buildings2 } from "../../constants/backgrounds";
import { useAuth } from "../../helpers/context";
import ImageGallery from "react-image-gallery";
import { DARK_TEMPLATES } from "../../constants";
import { useMediaQuery } from "@mui/material";

export default function Page_header(props) {
  let HeaderTitle = props.name;
  const { vendor, templateId } = useAuth();
  const [images, setImages] = useState([]);
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const [path, setPath] = useState(vendor === "amakkn"
    ? process.env.REACT_APP_URL
    : "https://amakknbackendupload.s3.me-south-1.amazonaws.com/whitelabel/" +
    vendor);

  useEffect(() => {
    if (vendor) {
      setPath(vendor === "amakkn"
        ? process.env.REACT_APP_URL
        : "https://amakknbackendupload.s3.me-south-1.amazonaws.com/whitelabel/" +
        vendor);
    }
  }, [vendor]);

  let imgUrl = props.imageUrl ? props.imageUrl : path + "/assets/img/banner/1.jpg";
  const inlineStyle = {
    background: props.imageUrl ? "url(" + imgUrl + ") no-repeat center #D8D8D8" : `${DARK_TEMPLATES.includes(+templateId)?'var(--main-color-two)':'#D8D8D8'}`,
    backgroundSize: props.imgCover ? 'cover' : 'contain',
  };

  useEffect(() => {
    let temp = [];
    if (props?.slider && props?.images?.length> 0) {
      props?.images?.map(i => temp.push({ original: i, thumbnail: i }));
      setImages(temp);
    }
  }, [props?.images,props?.slider]);

  
 
  return (
    props.slider ? (<>
      <div className={`breadcrumb-area-slider breadcrumb-area ${props.isAdmin ? 'admin' : ''}`}>
        <span className="breadcrumb-area-slider-title page-title">{HeaderTitle}</span>
          <ImageGallery items={images} autoPlay showPlayButton={false} showFullscreenButton={false} showThumbnails={false} showBullets={images.length > 1} />
      </div>
    </>) : (
        <div className={`breadcrumb-area position-relative ${isLargeScreen || props.isAdmin ? "" : "mobile"} ${props.moreHeight ? 'more-height' : ''} ${props.isAdmin ? 'admin mt-0' : ''}`} style={inlineStyle}>
        {!props.imageUrl && <div className="breadcrumb-cover">
            {DARK_TEMPLATES.includes(+templateId) ? <Buildings2 /> : <Buildings />}
        </div>}
        {props.shareButton}
        {props.backButton ? props.backButton : <></>}
        <div className='breadcrumb-inner' data-aos='zoom-in'>
          <h1 className='page-title'>{HeaderTitle}</h1>
  
        </div>
      </div>
    )
  );
}
