import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "firebase/compat/analytics";
import { toast } from "react-toastify";
import { getMenuItems, getWLProfile } from "../../api/userApi";
import { onMessageListener } from "../../Config/Firebase";
import { useTranslation } from "react-i18next";
import {  WLAdministration, WLBlog, WLCall, WLHome, WLInfo, WLProjects, WLProperties } from "../../constants/icons";
import { useAuth } from "../../helpers/context";
import { DEMO_VENDORS } from "../../constants";
import AppsIcon from '@mui/icons-material/Apps';

function LinkTo(props) {
  return (
    <Link
      to={props.linkTo}
      className={`${props.classes || ""} ${
        props.activationLinks?.includes('/') ? (props.activationLinks?.includes(window.location.pathname) ? 'active-link' :""): props.activationLinks.filter(i=> window.location.pathname.includes(i))?.length>0
        ? 'active-link' : ""} ${props.component ? 'default-color':''} ` }>
      {<span>
        {typeof props.icon === "string" ? <i className={props.icon} /> : props.component ? props.icon : <props.icon />}
      </span>}
      <span>
        {props.name}
      </span>
    </Link>
  );
}

export default function BottomNavigation() {
  const { t, i18n } = useTranslation();
  const { token, vendor, setPlanEndDate, MenuData, setMenuData, setShowMenuPhone, iconPackage, setIconPackage, templateId } = useAuth();
  const [wlPlan, setWLPlan] = useState(undefined);
  const navExpand = useRef(null);
  const navExpandList = useRef(null);
  const navExpandIcon = useRef(null);

  useEffect(() => {
    if (vendor) {
      getWLProfile({ userName: vendor, language: i18n.language === 'ar' ? '0' : '1' }).then(
        (_userInfo) => {
          if (_userInfo.resCode === 0) {
            // setuserInfo(_userInfo.response.wlUser);
            setPlanEndDate(_userInfo.response.wlUser.planEndDate);
            setWLPlan(_userInfo.response.wlUser.planId);
          }
        }
      );
      if (DEMO_VENDORS.includes(vendor) && localStorage.getItem(`menu-${vendor}`)) {
        const menuStorage = JSON.parse(localStorage.getItem(`menu-${vendor}`));
        setMenuData(menuStorage?.menuData?.sort((a, b) => +a.loginRequired - +b.loginRequired));
        setShowMenuPhone(menuStorage?.showPhone === '1');
        setIconPackage(menuStorage?.iconPackage || '1');
          
      } else {
        getMenuItems({ userName: vendor, language: i18n.language === 'ar' ? '0' : '1' }).then(
          (_userInfo) => {
            if (_userInfo.resCode === 0) {
              setShowMenuPhone(_userInfo.response.showPhone === '1');
              setIconPackage(_userInfo.response.iconsPackage || '1');
              // seMenuItems(_userInfo.response.menuItems);
              setMenuData(_userInfo.response.menuItems.sort((a, b) => +a.loginRequired - +b.loginRequired));
            }
          }
        );
      }
      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, vendor, i18n.language]);

  if (token)
    onMessageListener()
      .then((payload) => {
        toast.dark(
          payload.notification.title + ": " + payload.notification.body
        );
      })
      .catch((err) => console.log("failed: ", err));
  

  return (
    <>
      <nav className="bottom-navigation">
        <ul className="list">
          <li>
            <LinkTo
              linkTo="/"
              activationLinks={["/"]}
              icon={<WLHome packageId={iconPackage} templateId={templateId} />}
              component
              classes="link"
              name={t("Home")}
            />
          </li>
          {MenuData && MenuData?.length > 0 &&
            MenuData?.map(
              (menuElement, idx) =>
                menuElement.isVisible !== "0" && (
                  (menuElement.key === "5" || menuElement.key === "6" || menuElement.key === "3")
                    // (menuElement.key === "1" && (templateID === "1" || templateID === "3")) ||
                    ? null
                    : <li
                      key={idx}>
                      <LinkTo
                        classes="link"
                        activationLinks={menuElement.key === "2" ? [menuElement.linkTo, 'properties'] : [menuElement.linkTo]}
                        linkTo={menuElement.key === "2" ? menuElement.linkTo + (
                          ['6', '7', '8', '9'].includes(wlPlan) ? '/3/all/24.716199523004914/46.671776478222675/createdAt/11/1' : ''
                        ) : menuElement.linkTo}
                        icon={
                          menuElement.key === "1" ? <WLProjects packageId={iconPackage} templateId={templateId} /> :
                            menuElement.key === "2" ? <WLProperties packageId={iconPackage} templateId={templateId} /> :
                              // menuElement.key === "3" ? <WLInfo packageId={iconPackage} templateId={templateId} /> :
                              menuElement.key === "4" ? <WLCall packageId={iconPackage} templateId={templateId} /> :
                                // menuElement.key === "5" ? <WLAdministration packageId={iconPackage} templateId={templateId} /> :
                                <WLBlog packageId={iconPackage} templateId={templateId} />}
                        component
                        name={menuElement.name}
                      />
                    </li>
                )
            )}
           
          <li>
            <button className="expand" ref={navExpand} style={{ gap: '5px' }} onClick={() => {
              navExpandList.current.classList.toggle('show-list')
              navExpandIcon.current.classList.toggle('rotate-icon')
            }}>
              <span ref={navExpandIcon} className="expand-icon" >
                <AppsIcon sx={{ color: 'var(--main-color-one)' }} />
              </span>
              <span style={{ fontSize: '14px', color: '#212121', minHeight: '23px' }}>
                {t("More")}
              </span>
            </button>

            <ul className="expand-list" ref={navExpandList}>
              {MenuData && MenuData?.length > 0 &&
                MenuData?.map(
                  (menuElement, idx) =>
                    menuElement.isVisible !== "0" && (
                      (menuElement.key === "1" || menuElement.key === "2" || menuElement.key === "4") ?
                        null
                        : <li
                          key={idx}>
                          <LinkTo
                            classes="expand-link"
                            activationLinks={[menuElement.linkTo]}
                            linkTo={menuElement.key === "2" ? menuElement.linkTo + (
                              ['6', '7', '8', '9'].includes(wlPlan) ? '/3/all/24.716199523004914/46.671776478222675/createdAt/11/1' : ''
                            ) : menuElement.linkTo}
                            icon={
                              // menuElement.key === "1" ? <WLProjects packageId={iconPackage} templateId={templateId} /> :
                              // menuElement.key === "2" ? <WLProperties packageId={iconPackage} templateId={templateId} /> :
                              menuElement.key === "3" ? <WLInfo packageId={iconPackage} templateId={templateId} /> :
                                // menuElement.key === "4" ? <WLCall packageId={iconPackage} templateId={templateId} /> :
                                menuElement.key === "5" ? <WLAdministration packageId={iconPackage} templateId={templateId} /> :
                                  <WLBlog packageId={iconPackage} templateId={templateId} />}
                            component
                            name={menuElement.name}
                          />
                        </li>
                    )
                )}
            </ul>
          </li>
         
          
        </ul>
      </nav>
    </>
  );
}