import React, { useState, useEffect } from "react";
import { getListOfAllSubjects, sendFeedback } from "../../api/generalAPI";
import PageHeader from "../../components/global-components/page-header";
import AppLayout from "../../components/layouts/app-layout";
import { getWLProfile, wlContactUs } from "../../api/userApi";
import { FormControl, InputLabel, TextField, Select, FormHelperText, MenuItem, OutlinedInput, Alert, Box, useMediaQuery } from "@mui/material";
import { Marker, Tel } from "../../constants/icons";
import { MailOutline, WhatsApp } from "@mui/icons-material";
import LoadingButton from "../../components/global-components/loading-btn";
import { preventString, resolvePersianAndArabicNumbers, resolveWhatsAppLink, trimPhone, validateEmail, validatePhone } from "../../helpers";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useAuth } from "../../helpers/context";
import { DARK_TEMPLATES, MODERN_TEMPLATES } from "../../constants";
import { useTranslation } from "react-i18next";

export const ContactForm = ({ variant }) => {
  const [IssueType, setIssueType] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const [user, setuser] = useState({
    name: "",
    email: "",
    message: "",
    subjectId: ""
  });
  const [errors, setErrors] = useState({});
  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });

    getListOfAllSubjects({
      language: i18n.language === 'ar' ? '0' : '1',
    }).then((_IssueType) => {
      if (_IssueType.resCode === 0) {
        setIssueType(_IssueType.response.subjectArray);
      }
    });
  }, [i18n.language]);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      formIsValid();
    }
  },// eslint-disable-next-line
    [i18n.language]);

  function formIsValid() {
    const _errors = {};
    if (!user.name) _errors.name = t("EnterName");
    if (!user.subjectId) _errors.subjectId = t("PleaseSelectTheSubject");
    if (!user.message) _errors.message = t("PleaseEnterTheMessage");
    if (!user.email) _errors.email = t("PleaseEnterAnEmail");
    else if (!validateEmail(user.email)) _errors.email = t("PleaseEnterValidEmail");

    setErrors(_errors);
    // Form is valid if the errors object has no properties
    return Object.keys(_errors).length === 0;
  }

  function onSubmit() {
    if (!formIsValid()) return;
    setLoadingButton(true);
    sendFeedback({
      language: i18n.language === 'ar' ? '0' : '1',
      subjectId: user.subjectId,
      name: user.name,
      email: user.email,
      message: user.message,
      userId: "",
    }).then((_FeedBackInfo) => {
      if (_FeedBackInfo.resCode === 0) {
        Swal.fire({
          title: t("DoneSuccessfully"),
          text: t("MessageSentSuccessfully"),
          icon: 'success',
          confirmButtonText: t("Close")
        });
        setuser({
          ...user,
          name: "",
          email: "",
          message: "",
          subjectId: ''
        });
      }
      else
        Swal.fire({
          title: t("AnErrorOccurred"),
          text: _FeedBackInfo.resStr,
          icon: 'error',
          confirmButtonText: t("OK2")
        });
      setLoadingButton(false);
    }).catch(e => setLoadingButton(false));
  }
  return (
    <div className={`contact-form-wrap ${variant === 'two-rows' ? 'row' : ''}`}>
      <div className={variant === 'two-rows' ? 'col-12 col-md-4 col-lg-4 mb-3' : 'mt-3'}>
        <FormControl
          error={Boolean(errors.email)}
          className="col-12"
        >
          <TextField
            label={t("Email")}
            sx={{ width: "100%",'& input': { direction: 'ltr', textAlign: 'left',textAlignLast:'left' } }}
            type='text'
            inputProps={{ maxLength: 50 }}
            InputLabelProps={{
              shrink: true,
            }}
            autoComplete="off"
            required
            variant={'outlined'}
            size='small'
            onChange={(e) => {
              setuser({ ...user, email: resolvePersianAndArabicNumbers(e.currentTarget.value) });
            }}
            error={Boolean(errors.email)}
            value={user.email}
          />
          {errors.email &&
            <FormHelperText component={Box}>
              <Alert severity="error" className="custom-alert">
                {errors.email}
              </Alert>
            </FormHelperText>}
        </FormControl>
      </div>
      <div className={variant === 'two-rows' ? 'col-12 col-md-4 col-lg-4 mb-3' : 'mt-3'}>
        <FormControl
          error={Boolean(errors.name)}
          className="col-12"
        >
          <TextField
            label={t("Name")}
            sx={{ width: "100%" }}
            type='text'
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ maxLength: 50 }}
            autoComplete="off"
            required
            variant={'outlined'}
            size='small'
            onChange={(e) => {
              setuser({ ...user, name: resolvePersianAndArabicNumbers(e.currentTarget.value) });
            }}
            error={Boolean(errors.name)}
            value={user.name}
          />
          {errors.name &&
            <FormHelperText component={Box}>
              <Alert severity="error" className="custom-alert">
                {errors.name}
              </Alert>
            </FormHelperText>}
        </FormControl>
      </div>
 
      <div className={variant === 'two-rows' ? 'col-12 col-md-4 col-lg-4 mb-3' : 'mt-3'}>
        <FormControl
          required
          error={errors.subjectId ? true : false}
          className="col-12 "
        >
          <InputLabel id="subjectId-label">{t("Subject")}</InputLabel>
          <Select
            name="subjectId"
            labelId="subjectId-label"
            variant="outlined"
            size="small"
           
            input={<OutlinedInput
              notched
              label={t("Subject")}
              id="subjectId-label"
            />}
            label={t("Subject")}
            value={user.subjectId}
            onChange={(e) => setuser({ ...user, subjectId: resolvePersianAndArabicNumbers(e.target.value) })
            }
          >
            {IssueType.map((item) => <MenuItem value={item.subjectId} key={item.subjectId}>{item.subject}</MenuItem>)}
          </Select>
          {errors.subjectId &&
            <FormHelperText component={Box}>
              <Alert severity="error" className="custom-alert">
                {errors.subjectId}
              </Alert>
            </FormHelperText>}
        </FormControl>
      </div>
 
      <div className={variant === 'two-rows' ? 'col-12 mb-4' : 'mt-3 mb-3'}>
        <FormControl
          error={Boolean(errors.message)}
          className="col-12"
        >
          <TextField
            variant={'outlined'}
            multiline
            sx={{ width: "100%" }}
            InputLabelProps={{ shrink: true }}
            rows={8}
            inputProps={{ maxLength: 500 }}
            label={t("Message")}
            required
            value={user.message}
            onChange={(e) =>
              setuser({ ...user, message: resolvePersianAndArabicNumbers(e.currentTarget.value?.length >= 500 ? e.currentTarget.value?.substring(0, 500) : e.currentTarget.value) })
            }
            error={Boolean(errors.message)}
          />
          <h6 className='rightalign mb-2 mt-1'>
            {user?.message
              ? user?.message?.length + "/500"
              : "0/500"}
          </h6>
          {errors.message &&
            <FormHelperText component={Box}>
              <Alert severity="error" className="custom-alert">
                {errors.message}
              </Alert>
            </FormHelperText>}
        </FormControl>
      </div>
      <div className='btn-wrap text-center'>
        <LoadingButton
          classes={`primary-btn w-100 d-flex align-items-center justify-content-center mb-3 ${variant === 'two-rows' ? 'py-2' : 'btn primary-btn w-100  mx-auto'}`}
          label={t("Send")}
          loading={loadingButton}
          handleClick={onSubmit}
        />
      </div>
    </div>);
};

export const ContactForm2 = ({ data, vendorName, variant }) => {
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const [loadingButton, setLoadingButton] = useState(false);
  const { templateId } = useAuth();
  const [user, setuser] = useState({
    name: "",
    email: "",
    message: "",
    phone: '',
    subject: ''
  });
  const [errors, setErrors] = useState({});
  const { t, i18n } = useTranslation();

  function formIsValid() {
    const _errors = {};
    if (!user.name) _errors.name = t("EnterName");
    if (!user.subject) _errors.subject = t("PleaseSelectTheSubject");
  
    if (!user.phone) _errors.phone = t("EnterPhoneNumber");
    else if (!validatePhone(user.phone)) _errors.phone = t("PhoneNumberIsIncorrect");
    if (!user.message) _errors.message = t("PleaseEnterTheMessage");
    if (!user.email) _errors.email = t("PleaseEnterAnEmail");
    else if (!validateEmail(user.email)) _errors.email = t("PleaseEnterValidEmail");
  
    setErrors(_errors);
    // Form is valid if the errors object has no properties
    return Object.keys(_errors).length === 0;
  }

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      formIsValid();
    }
  }, // eslint-disable-next-line
    [i18n.language]);
  
  function onSubmit() {
    if (!formIsValid()) return;
    setLoadingButton(true);
    wlContactUs({
      userName: vendorName,
      subject: user.subject,
      phone: user.phone,
      text: user.message,
      email: user.email,
      name: user.name,
      language: i18n.language === 'ar' ? '0' : '1'
    }).then((_FeedBackInfo) => {
      if (_FeedBackInfo.resCode === 0) {
        Swal.fire({
          title: t("DoneSuccessfully"),
          text: t("MessageSentSuccessfully"),
          icon: 'success',
          confirmButtonText: t("Close")
        });
        setuser({
          ...user, name: "",
          email: "",
          message: "",
          phone: '',
          subject: ''
        });
      }
      else
        Swal.fire({
          title: t("AnErrorOccurred"),
          text: _FeedBackInfo.resStr,
          icon: 'error',
          confirmButtonText: t("OK2")
        });
      setLoadingButton(false);
      
    }).catch(e => setLoadingButton(false));
  }
  return (
    <div className="row m-0">
      {[...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? (
        <div className="col-12 col-lg-4 contact-section">
        
          <div>
            <span className="circle circle-1"></span>
            <span className="circle circle-2"></span>
            <div style={{ marginBottom: '40px' }}>
              <h4 style={{ color: '#fff' }}>{t("ContactInformation")}</h4>
            </div>
            {data?.address?.trim() && (
              <p className="flex-info">
                <Marker />
                {data?.address?.includes('@') ? data?.address?.substring(0, data?.address?.indexOf('@')) : data?.address}
              </p>
            )}

            <p className="flex-info">
              <MailOutline />
              <a className="inverse-link" href={`mailto:${data.email}`}>{data.email}</a>
            </p>
            <p className="flex-info">
              <Tel />
              <a className="inverse-link" rel="noreferrer" target={isLargeScreen ? '_blank' : "_self"}  href={`tel:${trimPhone(data.phone)}`}>
                {trimPhone(data.phone)}
              </a>
            </p>
         
            <p className="flex-info">
              <WhatsApp />
              <a className="inverse-link" href={resolveWhatsAppLink(isLargeScreen, trimPhone(data.whatsapp))}>
                {trimPhone(data.whatsapp)}
              </a>
            </p>
          </div>
        </div>
      ) : null}
      <div className={`col-12 ${[...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? 'col-lg-8 mt-4 mt-lg-0 ' : ''} contact-form-wrap ${variant === 'two-rows' ? 'row ' : ''}`}>
  
        <div className={variant === 'two-rows' ? [...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? 'col-12 col-md-6 mb-3' : 'col-12 col-md-6 col-lg-3 mb-3' : 'mt-3'}>
          <FormControl
            error={Boolean(errors.email)}
            className="col-12"
          >
            <TextField
              label={t("Email")}
              sx={{ width: "100%",'& input': { direction: 'ltr', textAlign: 'left',textAlignLast:'left' } }}
              type='text'
              InputLabelProps={{
                shrink: true,
              }}
              required
              variant={'outlined'}
              inputProps={{ maxLength: 50 }}
              size='small'
              autoComplete="off"
              onChange={(e) => {
                setuser({ ...user, email: resolvePersianAndArabicNumbers(e.currentTarget.value) });
              }}
              error={Boolean(errors.email)}
              value={user.email}
            />
            {errors.email &&
              <FormHelperText component={Box}>
                <Alert severity="error" className="custom-alert">
                  {errors.email}
                </Alert>
              </FormHelperText>}
          </FormControl>
        </div>
        <div className={variant === 'two-rows' ? [...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? 'col-12 col-md-6 mb-3' : 'col-12 col-md-6 col-lg-3 mb-3' : 'mt-3'}>
          <FormControl
            error={Boolean(errors.name)}
            className="col-12"
          >
            <TextField
              label={t("Name")}
              sx={{ width: "100%" }}
              type='text'
              inputProps={{ maxLength: 50 }}
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="off"
              required
              variant={'outlined'}
              size='small'
              onChange={(e) => {
                setuser({ ...user, name: resolvePersianAndArabicNumbers(e.currentTarget.value) });
              }}
              error={Boolean(errors.name)}
              value={user.name}
            />
            {errors.name &&
              <FormHelperText component={Box}>
                <Alert severity="error" className="custom-alert">
                  {errors.name}
                </Alert>
              </FormHelperText>}
          </FormControl>
        </div>

        <div className={variant === 'two-rows' ? [...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? 'col-12 col-md-6 mb-3' : 'col-12 col-md-6 col-lg-3 mb-3' : 'mt-3'}>
          <FormControl
            error={Boolean(errors.phone)}
            className="col-12"
          >
            <TextField
              label={t("PhoneNumber")}
              sx={{ width: "100%" }}
              type='text'
              inputProps={{ maxLength: 15 }}
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="off"
              required
              variant={'outlined'}
              size='small'
              onChange={(e) => {
                setuser({ ...user, phone: preventString(e.currentTarget.value) });
              }}
              error={Boolean(errors.phone)}
              value={user.phone}
            />
            {errors.phone &&
              <FormHelperText component={Box}>
                <Alert severity="error" className="custom-alert">
                  {errors.phone}
                </Alert>
              </FormHelperText>}
          </FormControl>
        </div>

        <div className={variant === 'two-rows' ? [...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? 'col-12 col-md-6 mb-3' : 'col-12 col-md-6 col-lg-3 mb-3' : 'mt-3'}>
          <FormControl required className="w-100">
            <InputLabel shrink htmlFor='subject1'>{t("Subject")}</InputLabel>

            <Select
              sx={{ width: "100%" }}
              size="small"
              value={user.subject}
              onChange={(event) => {
                setuser({
                  ...user,
                  subject: event.target.value,
                });
              }}
              required
              input={<OutlinedInput
                notched
                label={t("Subject")}
                id="subject1"
              />}
              label={t("Subject")}
              inputProps={{
                name: "subject1",
                id: "subject1",
              }}
              labelId="subject"
            >
              <MenuItem value='إبلاغ عن مشكلة'>{t("ReportProblem")}</MenuItem>
              <MenuItem value='استفسار'>{t("Inquiry")}</MenuItem>
              <MenuItem value='طلب تواصل'>{t("ContactRequest")}</MenuItem>
            </Select>
          </FormControl>
          {errors.subject &&
            <FormHelperText component={Box}>
              <Alert severity="error" className="custom-alert">
                {errors.subject}
              </Alert>
            </FormHelperText>}
        </div>
        <div className={variant === 'two-rows' ? 'col-12 ' : 'mt-3'}>
          <FormControl
            error={Boolean(errors.message)}
            className="col-12"
          >
            <TextField
              variant={'outlined'}
              multiline
              sx={{ width: "100%" }}
              InputLabelProps={{ shrink: true }}
              rows={[...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? 5 : 8}
              label={t("Message")}
              required
              inputProps={{ maxLength: 500 }}
              value={user.message}
              onChange={(e) =>
                setuser({
                  ...user, message: resolvePersianAndArabicNumbers(e.currentTarget.value?.length >= 500 ? e.currentTarget.value?.substring(0, 500) : e.currentTarget.value)
                })
              }
              error={Boolean(errors.message)}
            />
            <h6 className='rightalign mb-2 mt-1'>
              {user?.message
                ? user?.message?.length + "/500"
                : "0/500"}
            </h6>
            {errors.message &&
              <FormHelperText component={Box}>
                <Alert severity="error" className="custom-alert">
                  {errors.message}
                </Alert>
              </FormHelperText>}
          </FormControl>
        </div>
        <div className={`btn-wrap text-center ${variant === 'two-rows' ? 'col-12 d-flex justify-content-end' : ''}`}>
          <LoadingButton
            type="submit"
            style={{ width: 'fit-content' }}
            classes={`primary-btn mb-3 d-flex align-items-center justify-content-center ${variant === 'two-rows' ? 'py-2' : 'btn w-100 mx-auto'}`}
            label={t("Send")}
            loading={loadingButton}
            handleClick={onSubmit}
          />
        </div>
      </div>

    </div>
  );
};

const Contact = () => {
  const [data, setData] = useState({});
  const { vendor, templateId } = useAuth();
  const { t, i18n } = useTranslation();
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    if (vendor) {
      getWLProfile({ userName: vendor, language: i18n.language === 'ar' ? '0' : '1' }).then((_userInfo) => {
        if (_userInfo.resCode === 0)
          setData(_userInfo.response.wlUser);
      });
    }
  }, [vendor, i18n]);
  
  return (
    <AppLayout withFooter pageTitle={vendor === 'amakkn' ? t("ContactUs") : t("CallUS")}>
      <PageHeader name={vendor === 'amakkn' ? t("ContactUs") : t("CallUS")} />
      <div className='contact-area pd-top-60 pd-bottom-65 '>
        <div className="col-12 contact-top">
          <h4>{t("FeelFreeToCallUS")}</h4>
          <p>{t("FillOutTheFormBelowAndClickTheSendButton")}</p>
        </div>
        <div className='col-12 pd-top-60'>
          {vendor === 'amakkn' ? <ContactForm variant="two-rows" /> : <ContactForm2 data={data} vendorName={vendor} variant="two-rows" />}
        </div>
        {vendor !== 'amakkn' && ![...[...MODERN_TEMPLATES, ...DARK_TEMPLATES], ...DARK_TEMPLATES].includes(+templateId) && <div className='row contact-info position-relative mx-0'>
          <span className="circle circle-1"></span>
          <span className="circle circle-2"></span>
          <div className="col-12" style={{ marginBottom: '90px' }}>
            <h4>{t("ContactInformation")}</h4>
            <p>{t("YouCanContactUsInOneOfTheFollowingWays")}</p>
          </div>
          {data?.address?.trim() && (
            <div className='col-xl-3 col-sm-6'>
              <div className='single-contact-info' data-aos='flip-left'>
                <p>
                  <Marker />
                  {t("Address")}
                </p>
                <h6>{data?.address?.includes('@') ? data?.address?.substring(0, data?.address?.indexOf('@')) : data?.address}</h6>
              </div>
            </div>
          )}

          <div className={data?.address?.trim() ? 'col-xl-3 col-sm-6' : 'col-xl-4'}>
            <div className='single-contact-info' data-aos='flip-left'>
              <p>
                <MailOutline />
                {t("Email")}
              </p>
              <h6><a className="inverse-link" href={`mailto:${data.email}`}>{data.email}</a></h6>
            </div>
          </div>
          <div className={data?.address?.trim() ? 'col-xl-3 col-sm-6' : 'col-xl-4'}>
            <div className='single-contact-info' data-aos='flip-left'>
              <p>
                <Tel />
                {t("PhoneNumber")}
              </p>
              <h6>
                <a className="inverse-link" rel="noreferrer" target={isLargeScreen ? '_blank' : "_self"} href={`tel:${trimPhone(data.phone)}`}>
                  {trimPhone(data.phone)}
                </a>
              </h6>
            </div>
          </div>
           
          <div className={data?.address?.trim() ? 'col-xl-3 col-sm-6' : 'col-xl-4'}>
            <div className='single-contact-info' data-aos='flip-left'>
              <p>
                <WhatsApp />
                {t("WhatsApp")}
              </p>
              <h6>
                <a className="inverse-link" href={resolveWhatsAppLink(isLargeScreen, trimPhone(data.whatsapp))}>
                  {trimPhone(data.whatsapp)}
                </a>
              </h6>
            </div>
          </div>
        </div>
        }
      </div>
    </AppLayout>
  );
};

export default Contact;
