import { confirmAlert } from "react-confirm-alert";
import { Apartment, Area, AreaForRent, BathRooms, BedRooms, Building2, BuildingYear, Compass, ConstructionArea, Elevator, Entrance, Farm, Flat, FloorArea, Floors, Frontispiece, Gallery, Height, Land, Office, Offices, Parking, Price, Resort, Roads, Store, Store2, Table, Villa, Wells, Width } from "../constants/icons";
import Cookies from "universal-cookie";
import { checkDomainAvailabilityWithAWS, getDomainOwner, getUserProile } from "../api/userApi";
import { Alert, Snackbar } from "@mui/material";
import NavigationIcon from '@mui/icons-material/Navigation';
import i18next, { t } from "i18next";
import LoadingButton from "../components/global-components/loading-btn";
import { Edit } from "@mui/icons-material";
import { toast } from "react-toastify";

const renderRoomIcon = (key) => {
  switch (key) {
    case "1":
    case "15":
    case "16":
    case "22":
      return <Roads />;
    case "8":
    case "12":
    case "27":
    case "40":
    case "43":
      return <BedRooms />;
    case "9":
    case "13":
    case "23":
    case "28":
      return <BathRooms />;
    case "10":
    case "2":
    case "14":
    case "17":
    case "24":
    case "29":
      return <Parking />;
    case "11":
    case "26":
      return <Frontispiece />;
    case "25":
    case "21":
      return <Offices />;
    case "6":
      return <Flat />;
    case "7":
      return <Entrance />;
    case "19":
    case "4":
      return <Store />;
    case "31":
      return <Table />;
    case "30":
      return <Wells />;
    case "20":
    case "5":
      return <Elevator />;
    case "18":
    case "3":
      return <Floors />;
    default:
      return <></>;
  }
} 
  
const renderFeatureIcon = (key) => {
  switch (key) {
    case "1":
    case "3":
    case "10":
    case "13":
    case "15":
    case "20":
    case "27":
    case "30":
    case "42":
    case '39':
    case '41':
          return <Area />;
    case "2":
    case "8":
    case "12":
    case "14":
    case "19":
    case "22":
    case "24":
    case "25":
    case "29":
    case "32":
    case "38":
    case '52':
    case '50':
    case '51':
    case '60':
    case '61':
      return <Width />;
    case "17":
      return <AreaForRent />;
    case "18":
    case "5":
      return <FloorArea />;
    case "6":
      return <BuildingYear />;
    case "7":
      return <Compass />;
    case "4":
    case "9":
    case "11":
    case "16":
    case "21":
    case "23":
    case "26":
    case "28":
    case "31":
    case "40":
    case '43':
      return <ConstructionArea />;
    case "33":
    case "34":
    case "35":
    case "36":
      return <Height />;
    case "100":
      return <Price />;
    default:
      return <></>;
  }
}

const renderFeatureIconCondition = (type, key) => {
  if ((type === '1' && key === "1") ||
    (type === '2' && key === "3") ||
    (type === '3' && key === "9") ||
    (type === '13' && key === "9") ||
    (type === '14' && key === "40") ||
    (type === '4' && key === "10") ||
    (type === '5' && key === "13") ||
    (type === '6' && key === "15") ||
    (type === '7' && (key === "20" || key === "21" || key === "42")) ||
    (type === '8' && (key === "23" || key === "7")) ||
    (type === '9' && key === "26") ||
    (type === '10' && (key === "27" || key === "29")) ||
    (type === '11' && (key === "30" || key === "32")) ||
    (type === '12' && (key === "41")) ||
    (type === '13' && (key === "39")) ||
    (type === '15' && (key === "20" || key === "43"))) {
    return true;
  }
  return false;
}

const renderRoomIconCondition = (type, key, extraRooms = false) => {
  if ((type === '2' && key === "3") ||
    (type === '3' && (key === "8" || key === "9")) ||
    (type === '13' && (key === "8" || key === "9")) ||
    (type === '14' && (key === "8" || key === "9")) ||
    (type === '4' && (key === "12" || key === "13")) ||
    (type === '6' && key === "18") ||
    (type === '1' && key === "1" && extraRooms) ||
    (type === '7' && key === "16" && extraRooms) ||
    (type === '9' && key === "25") ||
    (type === '10' && key === "28") ||
    (type === '12' && (key === "8" || key === "9")) ||
    (type === '11' && key === "30")) {
    return true;
  }
  return false;
}
  
const renderFeaturesForWhatsAppMsg = (item) => {
  let content = "";
  item.features.map((feature, i) => {
      if (renderFeatureIconCondition(item.propertyType, feature.key)) {
          content += `${feature.name}: ${feature.key !== '7'
              ? Intl.NumberFormat("en").format(feature.value) + " " + feature.unit
              : getFontspace(feature.value)}\n`;
      }
      return null;
  });

  item.rooms.map((room, i) => {
      if (renderRoomIconCondition(item.propertyType, room.key, true)) {
          content += `${room.name}: ${room.value}\n`;
      }
      return null;
  });
  return content;
}

const openDeleteModal = (title, desc, onOK, OKTitle,dark) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className={`custom-ui ${dark ? 'dark' : ''}`}>
          <h3>{title}</h3>
          <h5 style={{ marginBottom: "15px" }}>
            {desc}
          </h5>
          <div className='btns-wrapper'>
            <button
              className='outlined-btn px-3'
              onClick={() => {
                onClose();
              }}>
              {t("Cancel")}
            </button>
            <button
              className={`primary-btn px-4 ${OKTitle && OKTitle !== t("Skip") ? '' : 'delete-btn'}`}
              onClick={() => {
                onClose();
                onOK();
              }}>
             {OKTitle || t("Delete") } 
            </button>
          </div>
        </div>
      );
    },
    closeOnEscape: true,
    closeOnClickOutside: true,
    willUnmount: () => { },
    afterClose: () => { },
    onClickOutside: () => { },
    onKeypressEscape: () => { },
    overlayClassName: `overlay-custom-class-name ${dark ? 'dark' : ''}`,
  });
}

const openChangePageConfirmationModal = (title, desc, onOK, onCancel) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className='custom-ui'>
          <h3>{title}</h3>
          <h5 style={{ marginBottom: "15px" }}>
            {desc}
          </h5>
          <div className='btns-wrapper'>
            <button
              className='outlined-btn px-3'
              onClick={() => {
                onClose();
                onCancel();
              }}>
              {t("Cancel")}
            </button>
            <button
              className='primary-btn px-4'
              onClick={() => {
                onClose();
                onOK();
              }}>
             {t("YesIKnowThat")}
            </button>
          </div>
        </div>
      );
    },

  });
}

const renderPropertyIcon = (key) => {
  switch (key) {
    case "1":
    case "5":
      return <Land />;
    case "2":
    case "6":
    case '14':
      return <Building2 />;
    case "3":
    case '13':
      return <Apartment />;
    case "4":
      return <Villa />;
    case "7":
      return <Store2 />;
    case "8":
      return <Gallery />;
    case "9":
    case '15':
      return <Office />;
    case "10":
      return <Resort />;
    case "11":
      return <Farm />;
    default:
      return <></>;
  }
}

const renderPropertyIcon2 = (key) => {
  switch (key) {
    case "1":
      return <Land />;
    case "26":
    case "2":
      return <Building2 />;
    case "3":
    case "5":
    case "6":
      return <Apartment />;
    case "4":
      return <Villa />;
    case "12":
      return <Store2 />;
    case "10":
      return <Gallery />;
    case "11":
      return <Office />;
    case "7":
      return <Resort />;
    case "25":
      return <Farm />;
    default:
      return <></>;
  }
}

const renderPropertyTypeName = (key) => {
  switch (key) {
    case "1":
      return t("Land");
    case "2":
      return t("Building");
    case "3":
      return t("Apartment");
    case "4":
      return t("Villa");
    case "5":
      return t("Land");
    case "6":
      return t("Building");
    case "7":
      return t("Warehouse");
    case "8":
      return t("Showroom");
    case "9":
      return t("Office");
    case "10":
      return t("Resort");
    case "11":
      return t("Farm");
    case "12":
      return t("Studio");
    case "13":
      return t("Room");
    case "14":
      return t("Floor");
    default:
      return "";
  }
}

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const validateGTMID = (id) => String(id).match(/^GTM-[A-Z0-9]{8}$/);
const validateAnalyticsID = (id) => String(id).match(/^G-[A-Z0-9]{10}$/);

const validateNumber = (number) => {
  return String(number)
    .match(
      /^[0-9]*$/
    );
};

const validatePassword = (password) => {
  return String(password)
    .match(
      /^[\s\S]{6,}$/
    );
};

const validatePhone = (phone) => {
  // return String(phone)
  //   .match(
  //     /^[^ 966][0-9]{8}$/
  //   );
  return String(phone)
  .match(
    /^[0-9]*$/
  );
};

const preventString = (str) => {
  return resolvePersianAndArabicNumbers(String(str).replace(/[a-zA-Z\u0621-\u064A\s]/g, ""));
};

const acceptNumbersAndStrings = (str) => {
  return String(str).replace(/[^a-zA-Z0-9]/gi, "");
};

const saveCookie = (key, value, maxAge = 60 * 60 * 24 * 100) => {
  if (value)
    new Cookies().set(key, JSON.stringify(value), { maxAge, path: '/' });
  else
    new Cookies().remove(key);
};

const getCookie = (key) => {
  return new Cookies().get(key);
}

const removeZeroFromNumberIfExists = (number) => {
  if (number.startsWith('0'))
    return number.substring(1);
  return number;
}

// const p2e = s => s.replace(/[۰-۹]/g, d => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d));
// const a2e = s => s.replace(/[٠-٩]/g, d => '٠١٢٣٤٥٦٧٨٩'.indexOf(d));

const persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g],
  arabicNumbers = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g],
  resolvePersianAndArabicNumbers = (str) => {
    if (typeof str === 'string') {
      for (let i = 0; i < 10; i++) {
        str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
      }
    }
    return str;
  };

  const validateInstagramURL = (url) => {
    return url ?resolvePersianAndArabicNumbers(url)
      .toLowerCase()
      .match(
        /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/
      ):true;
  };
  const validateFacebookURL = (url) => {
    return url ? resolvePersianAndArabicNumbers(url)
      .toLowerCase()
      .match(
        /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/
      ): true;
};
const validateTwitterURL = (url) => {
  return url ? resolvePersianAndArabicNumbers(url)
    .toLowerCase()
    .match(
      /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/
    ): true;
};
const parseUsername = (url) => {
  let matches = url.match(/(?:https?:\/\/)?(?:www.)?(?:twitter|facebook|instagram)(?:.com\/)?([@a-zA-Z0-9-_]+)/im);
  return matches?.length>0 ? matches[1] : url;
};

const getVendorName = async () => {
  let vendorName = '';
  if (window.location.hostname.includes('inme.com.sa')) {
    vendorName = 'amakkn';
  } else {
    if (window.location.hostname !== 'localhost' && !window.location.hostname.includes('amakkn.')) {
      let res = await getDomainOwner({ domain: window.location.hostname.includes('www.') ? window.location.hostname.substring(4) : window.location.hostname });
      vendorName = res?.response?.userName;
    } else {
      vendorName = window.location.hostname.split(".")[0];
      if (vendorName === "localhost")
        vendorName = process.env.REACT_APP_LOCALHOST_VENDOR;
      else if (vendorName === "www")
        vendorName = window.location.hostname.split(".")[1];
    }
  }

  return vendorName;
}

const validateURL = (url) => {
  const parsed = new URL(url);
  return ['https:', 'http:'].includes(parsed.protocol);
};

const getFontspace = (name) => {
  switch (name) {
    case "North":
      return t("North");

    case "South":
      return t("South");

    case "East":
      return t("East");

    case "West":
      return t("West");

    case "North-West":
      return t("NorthWest");

    case "North-East":
      return t("NorthEast");

    case "South-East":
      return t("SouthEast");

    case "South-West":
      return t("SouthWest");
    case "3-Streets":
      return t("3Streets");
    case "4-Streets":
      return t("4Streets");
    default:
      return t("NotAvailable");
  }
}

const getDemoSnakbar = (open, setOpen) => {
  return (
    <Snackbar
      anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }}
      open={open}
      sx={{ zIndex: 99999999999 }}
      autoHideDuration={4500}
      onClose={(event, reason) => {
        if (reason === 'clickaway') return;
        setOpen(false);
      }}>
      <Alert onClose={(event, reason) => {
        if (reason === 'clickaway') return;
        setOpen(false);
      }} severity="warning" sx={{ width: '100%' }}>
        &nbsp; {t("YouCannotProceedWithDemoAccount")} &nbsp;
      </Alert>
    </Snackbar>
  );
}

const trimPhone = (phone = "") => {
  if (phone?.startsWith('+966')) {
    return '0' + phone.substring(4);
  } else if (phone?.startsWith('966')) {
    return '0' + phone.substring(3);
  } else if (phone?.startsWith('00966')) {
    return '0' + phone.substring(5);
  } else if (phone?.startsWith('0966')) {
    return '0' + phone.substring(4);
  }else if (!phone?.startsWith('+') && !phone?.startsWith('0')) {
    return '0' + phone;
  } else {
    return phone;
  }
}

const resolvePhonePrefix = (phone = "") => {
  if (phone?.startsWith('966')) {
    return phone;
  } else if (phone?.startsWith('00966')) {
    return '966' + phone.substring(5);
  } else if (phone?.startsWith('0966')) {
    return '966' + phone.substring(4);
  }
  else if (phone?.startsWith('0')) {
    return '966' + phone.substring(1);
  } else if (phone?.startsWith('5')) {
    return '966' + phone;
  } else {
    return phone;
  }
}

const validateSAPhone = (phone) => {
  let reqex = /^5(5|0|3|6|4|9|1|8|7)([0-9]{7})$/;
  let phon = preventString(String(phone).replace(/[a-zA-Z\u0621-\u064A\s]/g, ""));
  if (phon !== "" && !reqex.test(phon)) {
    return false;
  }
  return true;
};

const getVideoId = (url) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return (match && match[2].length === 11)
    ? match[2]
    : null;
}

const priceFormat = (price) => {
  let K = "K";
  let M = "M";
  let B = "B";

  if (price < 1000) {
    return price;
  } else if (price < 10000) {
    let priceText =
      price.substring(0, 1) +
      (price.substring(1, 2) === "0" ? "" : "." + price.substring(1, 2)) +
      (price.substring(2, 3) === "0" ? "" : price.substring(2, 3));
    return priceText + K;
  } else if (price < 100000) {
    let priceText =
      price.substring(0, 2) +
      (price.substring(2, 3) === "0" ? "" : "." + price.substring(2, 3));
    return priceText + K;
  } else if (price < 1000000) {
    let priceText = price.substring(0, 3);
    return priceText + K;
  } else if (price < 10000000) {
    let priceText =
      price.substring(0, 1) +
      (price.substring(1, 2) === "0" ? "" : "." + price.substring(1, 2)) +
      (price.substring(2, 3) === "0" ? "" : price.substring(2, 3));
    return priceText + M;
  } else if (price < 100000000) {
    let priceText =
      price.substring(0, 2) +
      (price.substring(2, 3) === "0" ? "" : "." + price.substring(2, 3));
    return priceText + M;
  } else if (price < 1000000000) {
    let priceText = price.substring(0, 3);
    return priceText + M;
  } else {
    let priceText = "";
    if (price)
      priceText =
        price.substring(0, 1) +
        (price.substring(1, 2) === "0" ? "" : "." + price.substring(1, 2)) +
        (price.substring(2, 3) === "0" ? "" : price.substring(2, 3));
    return priceText + B;
  }
}

const mStyles = [
  {
    featureType: "administrative",
    elementType: "all",
    stylers: [
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#E4E4E4",
      },
    ],
  },
  {
    featureType: "landscape.man_made",
    elementType: "all",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#B2B2B2",
      },
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text",
    stylers: [
      {
        color: "#434343",
      },
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
];

const renderLocationTitle = (index) => {
  switch (index) {
    case 0: return t("Region");
    case 1: return t("City");
    case 2: return t("Neighborhood");
    case 3: return t("Street");
    case 4: return t("ZipCode");
    case 5: return t("BuildingNumber");
    case 6: return t("AdditionalNumber");
    default: return '';
  }
}

const resolveRerBordersValue = (value) => {
  switch (value) {
    case 'Road': return t("Street1");
    case 'Land': return t("Land");
    case 'East': return t("East1");
    case 'West': return t("West1");
    case 'South': return t("South1");
    case 'North': return t("North1");
    default: return value;
  }
}

const resolveRerBorders = (item, index, twoCol = false, table = false) => {
  return table ? (
    <tr key={index}>
      <td style={{ width: '40%' }}>
        {item?.split('&')?.[0] === "0" ? '' : resolveRerBordersValue(item?.split('&')?.[0])}
      </td>
      <td style={{ width: '60%' }}>
        {resolveRerBordersValue(item?.split('&')?.[1]) + " "}
        {t("WithLength")}: 
        {parseFloat(item?.split('&')?.[2]).toFixed(2) + ` ${t("Meter")}`}
      </td>
    </tr>
  ) : (
    <div
      className={`box col-12 ${twoCol ? 'col-lg-6' : ''} pr-0`}
      key={index}>
      <div className='icon blue'>
        <NavigationIcon sx={{ transform: index === 0 ? '' : index === 1 ? 'rotate(90deg)' : index === 3 ? 'rotate(180deg)' : 'rotate(-90deg)' }} />
      </div>
      <div className='info'>
        <span>
          <b>{item?.split('&')?.[0] === "0" ? '' : resolveRerBordersValue(item?.split('&')?.[0]) + ": "}</b>
          {resolveRerBordersValue(item?.split('&')?.[1]) + " "}
          <b>{t("WithLength")}: </b>
          {parseFloat(item?.split('&')?.[2]).toFixed(2) + ` ${t("Meter")}`}
        </span>
      </div>
    </div>
  );
}

const renderMeterIfNotExist = (item) => {
  return item.includes('متر') ? item : item + ` ${t("Meter")}`;
}

// const checkNoAdditionalInfo = (AIItem) => {
//   return (HIDDEN_ADDITIONAL_INFO_IF_NO.includes(AIItem.key) &&
//     AIItem.valueText !== '0' &&
//     AIItem.valueText !== 'لا' &&
//     AIItem.valueText !== 'لا يوجد' &&
//     AIItem.valueText !== 'لايوجد' &&
//     AIItem.valueText !== 'No' &&
//     AIItem.valueText !== 'no') || !HIDDEN_ADDITIONAL_INFO_IF_NO.includes(AIItem.key);
// }

const resolveUserTypeName = (userTypeKey) => {
  let userType;

  switch (userTypeKey) {
    case "1":
      userType = t("RegularUser");
      break;
    case "2":
      userType = t("PropertyOwner");
      break;
    case "3":
      userType = t("IndividualsAccount");
      break;
    case "4":
      userType = t("RealEstateCompany");
      break;
    case "5":
      userType = t("RealEstateCompanyAgent");
      break;
    default:
      break;
  }
  return userType;
}

const renderInterestingType = (type) => {
  switch (type) {
    case "بيع": return t("Sale");
    case "تأجير": return t("Renting");
    case "استئجار": return t("Rent");
    case "شراء": return t("Buying");
    default: return '';
  }
}

const renderPropertyType = (type) => {
  switch (type) {
    case "أرض": return t("Land");
    case "دور": return t("Floor");
    case "شقة": return t("Apartment");
    case "فيلا": return t("Villa");
    case "استوديو": return t("Studio");
    case "غرفة": return t("Room");
    case "استراحة": return t("Resort");
    case "معرض": return t("Showroom");
    case "مكتب": return t("Office");
    case "مستودع": return t("Warehouse");
    case "مزرعة": return t("Farm");
    case "عمارة": return t("Building");
    default: return '';
  }
}

const renderCustomerType = (type) => {
  switch (type) {
    case "مالك": return t("Owner");
    case "باحث": return t("Researcher");
    case "وسيط": return t("Broker");
    default: return '';
  }
}

const renderCustomerSeriousness = (type) => {
  switch (type) {
    case "منخفضة": return t("Low");
    case "متوسطة": return t("Medium");
    case "مرتفعة": return t("High");
    default: return '';
  }
}

const getGTMId = (str) => {
  const regExp = /GTM-[A-Z0-9]{8}/;
  const match = str.match(regExp);
  return (match)
    ? match?.[0]
    : str;
}

const getAnalyticsId = (str) => {
  const regExp = /G-[A-Z0-9]{10}/;
  const match = str.match(regExp);
  return (match)
    ? match?.[0]
    : str;
}

const containsArabic = (text) => {
  const arabicRegex = /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF]/;
  return arabicRegex.test(text);
}

const preventArabicString = (str) => {
  return resolvePersianAndArabicNumbers(String(str).replace(/[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\s0-9+-_]/g, ""));
};


const startFreeTrail = async (token) => {
  let _userInfo = await getUserProile({ userId: token, language: "1" });
  let temp = "";

  if (_userInfo?.resCode === 0) {
    let name = _userInfo.response.userProfile.name?.split(' ');
    
    if (name?.length > 1) {
      temp = (name?.[0] + name?.[name?.length - 1]).toLocaleLowerCase()?.replace(/[\s0-9+-_]/g, '');
    } else {
      temp = _userInfo.response.userProfile.name?.toLocaleLowerCase()?.replace(/[\s0-9+-_]/g, '');
    }
    if (containsArabic(temp) || !temp) {
      name = _userInfo.response.userProfile.managerName?.split(' ');
      if (name?.length > 1) {
        temp = (name?.[0] + name?.[name?.length - 1]).toLocaleLowerCase()?.replace(/[\s0-9+-_]/g, '');
      } else {
        temp = _userInfo.response.userProfile.managerName?.toLocaleLowerCase()?.replace(/[\s0-9+-_]/g, '');
      }
      if (containsArabic(temp) || !temp) {
        const characters = "abcdefghijklmnopqrstuvwxyz";
        const length = Math.floor(Math.random() * 5) + 3;
        let randomString = "";
        
        for (let i = 0; i < length; i++) {
          randomString += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        temp = randomString;
      }
    }
      
    let _items = await checkDomainAvailabilityWithAWS({
      domainNameWithoutExtension: temp,
      userId: token,
      free: '1',
      language: i18next.language === "ar" ? "0" : "1"
    });

    if (_items.resCode === 0) {
      return {
        domainNameWithoutExtension: temp,
        domainNameWithExt: _items.response?.[0]?.domain,
        error: false
      }
    } else {
      toast.error(_items.resStr);
      return {
        error: true
      };
    }
  }
}

const openConfirmTrailPopup = (domainNameWithExt, user, registerAmakknDomain, openChangeDomainDialog, domainNameWithoutExtension) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div
          className='custom-ui'
          style={{ maxHeight: "100%", overflow: "auto", direction: "rtl", position: 'relative' }}>
          <>
            <h3>الموافقة على اتفاقية استخدام اماكن برو</h3>
            <p className='my-4'>
              شكرًا لاختيارك <b>أماكن برو</b>. من خلال الاشتراك في هذه
              الخدمة، أنت <b>توافق</b> على الالتزام بالشروط والأحكام الآتية:
            </p>
            <ul className='mt-3 pb-3'>
              <li className='mb-3'>
                <b>التعريف بالخدمة</b>: اماكن برو هي خدمة مقدمة من{" "}
                <b>منصة أماكن</b> تتيح لك إنشاء وإدارة موقع عقاري بالنطاق
                المخصص (<b>{domainNameWithExt}
                  <span style={{ cursor: "pointer" }} onClick={() => openChangeDomainDialog(onClose)}>
                  <Edit sx={{ fontSize: '16px', color: 'var(--main-color-one)' }} /></span>
                </b>) للتسويق عن اعمالك وعرض
                اعلاناتك العقاريّة المرخصة.
              </li>
              <li className='mb-3'>
                <b>ملكية المواقع والنطاقات</b>: جميع المواقع والنطاقات التي
                تنشئ من خلال اماكن برو مملوكة لمنصة أماكن وتتيح هذه
                الاتفاقية للمستخدم الانتفاع منها ما دام مشتركاُ الا في حال
                الاخلال بشروط هذه الاتفاقية او اتفاقية استخدام منصة أماكن.
              </li>
              <li className='mb-3'>
                <b>اشتراطات الهيئة العامة للعقار</b>
                <ul className='mb-4 mt-1'>
                  <li>
                    هذه الخدمة مصرحة وخاضعة لرقابة الهيئة العامة للعقار.
                  </li>
                  <li>
                    تعديل المحتوى و إضافة الإعلانات العقاريّة المرخصة على
                    الموقع (<b>{domainNameWithExt}
                  <span style={{ cursor: "pointer" }} onClick={() => openChangeDomainDialog(onClose)}>
                  <Edit sx={{ fontSize: '16px', color: 'var(--main-color-one)' }} /></span>
                </b>) يكون متاح حصراً لـ (
                    <b>{user.name}</b>) المسجلة بالرقم الوطني الموحد\هوية
                    وطنية (
                    <b>
                      {user.accountType === "2"
                        ? user.commercialRecordNumber
                        : user.nationalid}
                    </b>
                    )
                  </li>
                  <li>
                    إضافة الإعلانات العقاريّة على الموقع تشترط وجود ترخيص
                    إعلان عقاري فعال مربوط بالرقم الوطني الموحد\الهوية
                    الوطنية (
                    <b>
                      {user.accountType === "2"
                        ? user.commercialRecordNumber
                        : user.nationalid}
                    </b>
                    ) وصادر عن طريق منصة الهيئة العامة للعقار.
                  </li>
                  <li>
                    تسجيل النطاق لدى المركز السعودي للأعمال خلال 14 يوم.
                  </li>
                  <li>
                    الامتثال لأي تشريعات قد تطرأ مستقبلاً من الجهات ذات
                    العلاقة.
                  </li>
                </ul>
              </li>
              <li className='mb-3'>
                <b>الرسوم والتجديد</b>
                <ul className='mb-4 mt-1'>
                  <li>
                    تُطبق رسوم اشتراك شهرية أو سنوية وفقًا للخطة المختارة.
                  </li>
                  <li>
                    تجدد الاشتراكات تلقائيًا في نهاية كل فترة اشتراك او في
                    حال انتهاء الفترة التجريبية ما لم يقوم المستخدم بإلغاء
                    الاشتراك.
                  </li>
                  <li>
                    يمكن إلغاء اشتراك "اماكن برو" في أي وقت، ولكن الرسوم
                    المدفوعة مقدمًا غير قابلة للاسترداد.
                  </li>
                </ul>
              </li>
              <li className='mb-3'>
                <b>التعديلات على الخدمة والشروط</b>
                <ul className='mb-4 mt-1'>
                  <li>
                    تحتفظ "أماكن" بالحق في تعديل أو إنهاء خدمة "اماكن برو"
                    أو تغيير الرسوم دون إشعار مسبق.
                  </li>
                  <li>
                    أي تغييرات في شروط الخدمة تصبح سارية المفعول بمجرد نشرها
                    في هذه الصفحة.
                  </li>
                </ul>
              </li>
            </ul>
                     
          </>
          
          <div style={{ position: 'sticky', bottom: '-32px', padding: '0 5px 15px', background: '#fff' }}>
            <hr className='my-0 py-0' />
            <p
              className='pb-0 px-0 pt-2 m-0'
              style={{ color: "#d00404", fontWeight: "bold" }}>
              باستخدامك لخدمة "اماكن برو"، أنت تقر وتوافق على الشروط
              والأحكام المذكورة أعلاه و
              <a
                href='https://www.amakkn.com/Terms'
                rel='noreferrer'
                target='_blank'
                style={{ textDecoration: "underline", marginRight: "4px" }}>
                اتفاقية استخدام منصة اماكن
              </a>
              .
                         
              و بضغطك على زر استمرار فأنت تؤكد على تجريب خدمة أماكن برو باستخدام النطاق (
              {domainNameWithExt}).
                           
              {`وتقر بأنّ جميع الإعلانات العقاريّة التي سوف تقوم بإضافتها تحمل رقم ترخيص تابع للسجل التجاري رقم /${user?.commercialRecordNumber}/`}
                       
            </p>
            <p style={{ fontWeight: "bold", marginTop: '5px' }}>
              نقدر ثقتك بأماكن ونعدك بتقديم خدمة استثنائية تلبي احتياجاتك
              العقاريّة.
            </p>
            <div className='btns-wrapper mt-2'>
              <LoadingButton
                loading={false}
                classes='primary-btn px-4'
                handleClick={() => {
                  registerAmakknDomain(onClose,domainNameWithoutExtension);
                }}
                label={t("Continue")}
              />
              <button
                className='outlined-btn px-3'
                onClick={() => {
                  openChangeDomainDialog(onClose);
                }}>
                {t("ChangeDomain")}
              </button>
              <button
                className='outlined-btn px-3'
                onClick={() => {
                  onClose();
                }}>
                {t("Close")}
              </button>
            </div>
          </div>
                 
        </div>
      );
    },
    overlayClassName: "custom-ui-width",
  });
};

const setItemWithExpiry = (key, value, ttl) => {
  const now = new Date();

  const item = {
    value: value,
    expiry: now.getTime() + ttl
  };

  localStorage.setItem(key, JSON.stringify(item));
}

const getItemWithExpiry = (key) => {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) return null;
  

  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
}

const resolveWhatsAppLink = (isLargeScreen, phone, text) => {
  if (isLargeScreen) {
    return 'https://wa.me/' + phone + (text ? `&text=${text}` : '');
  } else {
    return 'whatsapp://send?phone=' + phone + (text ? `&text=${text}` : '');
  }
};

const resolveInstagramLink = (isLargeScreen, userName) => {
  if (isLargeScreen) {
    return 'https://www.instagram.com/' + userName;
  } else {
    return 'instagram://user?username=' + userName;
  }
};

const resolveFacebookLink = (isLargeScreen, userName) => {
  if (isLargeScreen) {
    return 'https://www.facebook.com/' + userName;
  } else {
    return 'fb://profile/' + userName;
  }
};

const resolveTwitterLink = (isLargeScreen, userName) => {
  if (isLargeScreen) {
    return 'https://twitter.com/' + userName;
  } else {
    return 'twitter://user?screen_name=' + userName;
  }
};

const resolveSnapChatLink = (isLargeScreen, userName) => {
  if (isLargeScreen) {
    return 'https://www.snapchat.com/' + userName;
  } else {
    return 'snapchat://add/' + userName;
  }
};

                      
export { resolveSnapChatLink, resolveTwitterLink, resolveFacebookLink, resolveWhatsAppLink, resolveInstagramLink, preventArabicString, setItemWithExpiry, getItemWithExpiry, openConfirmTrailPopup, startFreeTrail, resolveRerBordersValue, validateAnalyticsID, validateGTMID, getAnalyticsId, getGTMId, renderCustomerSeriousness, renderCustomerType, renderPropertyType, renderInterestingType, renderFeaturesForWhatsAppMsg, resolvePhonePrefix, resolveUserTypeName, renderMeterIfNotExist, renderLocationTitle, resolveRerBorders, mStyles, priceFormat, getVideoId, validateSAPhone, trimPhone, getDemoSnakbar, renderPropertyIcon2, getFontspace, validateURL, getVendorName, parseUsername, validateTwitterURL,validateFacebookURL, validateInstagramURL, acceptNumbersAndStrings, preventString, validateNumber, validatePassword, validatePhone ,openChangePageConfirmationModal, resolvePersianAndArabicNumbers, removeZeroFromNumberIfExists, getCookie, saveCookie, validateEmail, renderPropertyTypeName, renderPropertyIcon, openDeleteModal, renderFeatureIcon, renderRoomIcon, renderFeatureIconCondition, renderRoomIconCondition };