import React from "react";
import PropTypes from "prop-types";
import { Alert, FormControl, FormHelperText, MenuItem, Select, TextField } from "@mui/material";
import LoadingButton from "../../global-components/loading-btn";
import { countryCodes } from "../../../constants";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";

function UserForm(props) {
  const recaptchaRef = props.recaptchaRef;
  const { t } = useTranslation();
  
  return (
    <form onSubmit={async (e) => {
      e.preventDefault();
      props.onSubmit(e);

    }}>
      <div className="col-12 col-md-5 col-lg-4 mx-auto row p-0">
        <FormControl
          error={props.errors.name}
          className="col-12 mb-3"
        >
          <TextField
            name='name'
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            autoComplete="off"
            size="small"
            label={props.name}
            value={props.user.name}
            onChange={props.onChange}
          />
          <FormHelperText>
            {props.errors.name ?
              <Alert severity="error" className="custom-alert">
                {props.errors.name}
              </Alert>
              : ''
            }
          </FormHelperText>
        </FormControl>
        <div className="row phone-wrapper col-12 mx-0 p-0">
        <div className='col-4 mb-2 p-0 form-group'>
            <Select
              id='countryCode'
              name='countryCode'
              onChange={props.onChange}
              value={props.user.countryCode || ""}
              className='sortDDL withBg h-35'>
              {countryCodes.map(item => (<MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>))}
            </Select>
        </div>
        <FormControl
          required
          error={props.errors.phone}
          className="col-8 p-0"
        >
          <TextField
            name='phone'
            variant="outlined"
            autoComplete="off"
            size="small"
            sx={{ width: '100%', '& input': { direction: 'ltr', textAlign: 'left',textAlignLast:'left' } }}
            InputLabelProps={{ shrink: true }}
            label={t("PhoneNumber")}
            value={props.user.phone}
            onChange={props.onChange}
          />
          </FormControl>
          <FormHelperText>
            {props.errors.phone ?
              <Alert severity="error" className="custom-alert mt-0">
                {props.errors.phone}
              </Alert>
              : ''
            }
          </FormHelperText>
        </div>
      
        <div className='col-12 p-0 recaptcha-wrapper'>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_ReCAPTCHA}
            theme='light'
            size="invisible"
            onExpired={() => {
              recaptchaRef.current?.reset();
            }}
            ref={recaptchaRef}
          />
        </div>
        <div className='col-12 mt-3 p-0'>
          <LoadingButton
            classes='primary-btn py-2 px-3 w-100 d-flex justify-content-center'
            label={t("Next")}
            type='submit'
            loading={props.loading}
          />
        </div>
      </div>
    </form>
  );
}

UserForm.propTypes = {
  user: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export default UserForm;
