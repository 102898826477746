import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { Link, useNavigate } from "react-router-dom";
import {
  addFavourite,
  removeFavourite,
  getREGASubjects,
  createREGAIssue,
  getPropertyDescription,
} from "../../../../api/propertyApi";
import {
  createATicket,
  getBrokerRegaInfo,
  getTicketListSubjects,
  getUserPlan,
  initiateChatChannel,
} from "../../../../api/userApi";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import GoogleMapsContainer from "../../../../components/googleMapComponent";
import { toast } from "react-toastify";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import LoginPopup from "../../../../components/user-components/login-popup";
import {
  CircularCheck,
  GreenCheck,
  ListArrow,
  Mail,
  Marker,
  RedClose,
  Report,
  Tel,
  Twitter,
} from "../../../../constants/icons";
import RecomandProperties from "../../../../components/section-components/recomand-properties";
import {
  getDemoSnakbar,
  getFontspace,
  renderFeatureIcon,
  renderLocationTitle,
  renderMeterIfNotExist,
  renderRoomIcon,
  resolveRerBorders,
  resolveUserTypeName,
} from "../../../../helpers";
import {
  Alert,
  AlertTitle,
  Avatar,
  Badge,
  ClickAwayListener,
  FormControl,
  FormHelperText,
  ImageList,
  ImageListItem,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import ImageModal from "../../../../components/global-components/image-modal";
import ShareIcon from "@mui/icons-material/Share";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Close, Summarize } from "@mui/icons-material";
import { useAuth } from "../../../../helpers/context";
import { styled } from "@mui/material/styles";
import {
  CORPORATE_LICENSE_URI,
  DARK_TEMPLATES,
  DEMO_VENDORS,
  INDIVIDUAL_LICENSE_URI,
} from "../../../../constants";
import QRCode from "react-qr-code";
import DescriptionIcon from "@mui/icons-material/Description";
import RoomIcon from "@mui/icons-material/Room";
import Forbidden from "../../403";
import PropertyPapper from "../../../../components/property-paper";
import LoadingButton from "../../../../components/global-components/loading-btn.js";
import Helmet from "react-helmet";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import VideoSnapshot from "video-snapshot";
import CampaignIcon from "@mui/icons-material/Campaign";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import MapIcon from "@mui/icons-material/Map";
import SignpostIcon from "@mui/icons-material/Signpost";
import LocalConvenienceStoreIcon from "@mui/icons-material/LocalConvenienceStore";
import FmdBadIcon from "@mui/icons-material/FmdBad";
import ApprovalIcon from "@mui/icons-material/Approval";
import Filter3Icon from "@mui/icons-material/Filter3";
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import BubbleChartIcon from "@mui/icons-material/BubbleChart";
import NavigationIcon from "@mui/icons-material/Navigation";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EditCalendarIcon from "@mui/icons-material/EventRepeat";
import moment from "moment";
import { useTranslation } from "react-i18next";
import SetPropertyLocationDialog from "./set-property-location-dialog";
import Swal from "sweetalert2/dist/sweetalert2.js";
import PropertyLoader from "./property-loader.js";

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 45,
  height: 45,
  bgcolor: "var(--main-color-one)",
  marginLeft: "10px",
  marginTop: "-5px",
  border: `2px solid ${theme.palette.background.paper}`,
}));

const publicUrl = process.env.REACT_APP_URL + "/";

const PropertyDetails = (props) => {
  const { token, setToken, UserId, setUserId, vendor, templateId } = useAuth();
  const history = useNavigate();
  const images = [];
  const isAdmin = window.location.href.includes("admin");
  const [openDemoSnackBar, setOpenDemoSnackBar] = React.useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [isSubmittingTicket, setIsSubmittingTicket] = useState(false);
  const [openMapDialog, setOpenMapDialog] = useState({
    visible: false,
    property: undefined,
  });
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  let propertyId = props.id;
  const [_property, set_property] = useState(false);
  const [_msg, set_msg] = useState(false);
  const [_code, set_code] = useState(1);
  const [propertyLicenseNumber, setPropertyLicenseNumber] = useState(undefined);
  const { t, i18n } = useTranslation();
  const [NewTicket, setNewTicket] = useState({
    subjectId: "",
    language: i18n.language === 'ar' ? '0' : '1',
    screenshots: "",
    typeOfFile: "",
    notes: "",
    propertyId: _property.propertyId,
  });
  const [TicketSubject, setTicketSubject] = useState([]);
  const [expandedTheDesc, setExpandedTheDesc] = React.useState(false);
  const [inFav, setinFav] = useState("0");
  const isMap = props.isMap;
  const [ExploreNeighbourhood, setExploreNeighbourhood] = useState({
    hospital: false,
    mosque: false,
    school: false,
    shopping_mall: false,
    // bus_station: false,
    // train_station: false,
  });

  const [selectedAttachment, setSelectedAttachment] = React.useState(undefined);
  const [imageModalOpened, setImageModalOpened] = React.useState(false);
  const [License, setLicense] = useState();
  const [videosToShow, setVideosToShow] = React.useState([]);
  let _photos = [],
    FloorPlans = [],
    videos = [];

  useEffect(() => {
    if (window?.location?.href?.includes("#report") && _property) {
      if (_property.hostInfo.id !== UserId) {
        document
          ?.getElementById("report")
          ?.scrollIntoView({ behavior: "smooth" });
        if (token) {
          setShowReportModal(true);
        }
      }
    }
  }, [token, _property, UserId]);

  const getData = () => {
    getPropertyDescription(getProps(propertyId, i18n.language === 'ar' ? '0' : '1')).then((res) => {
      setLicense(undefined);
      set_property(res.response);
      if (
        res.response?.hostInfo?.id === UserId &&
        res.response?.latitude === "24" &&
        res.response?.longitude === "24"
      ) {
        Swal.fire({
          title: t("Notice"),
          text: t("ThePropertyLocationIsIncorrect"),
          icon: "warning",
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: true,
          confirmButtonText: t("SelectNow"),
          cancelButtonText: t("Ignore"),
        }).then((result) => {
          if (result.isConfirmed) {
            setOpenMapDialog({ visible: true, property: res.response });
          }
        });
      }

      setNewTicket({ ...NewTicket, propertyId: res.response.propertyId });
      setPropertyLicenseNumber(
        res?.response?.additionalInfo?.filter((i) => i.key === "10")?.[0]
          ?.valueText
      );
      set_msg(res.resStr);
      set_code(res.resCode);
    });
  };

  useEffect(() => {
    // let isnum = /^\d+$/.test(propertyId);
    // if (!isnum)
    getData();

    if (!isMap) window.scroll({ top: 0, left: 0, behavior: "smooth" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyId, i18n.language]);

  useEffect(() => {
    if (token)
      getTicketListSubjects({
        language: i18n.language === 'ar' ? '0' : '1',
        isProperty: "1",
      }).then((_TicketSubject) =>
        setTicketSubject(_TicketSubject.response.ticketSubjectArray)
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, i18n.language]);

  let options = TicketSubject.map((__TicketSubject) => ({
    value: __TicketSubject.subjectId,
    label: __TicketSubject.subject,
  }));

  useEffect(() => {
    setinFav(_property.isInFavourites);
    setExploreNeighbourhood({
      hospital: false,
      mosque: false,
      school: false,
      shopping_mall: false,
      // bus_station: false,
      // train_station: false,
    });
  }, [_property]);

  function getProps(propertyId, language) {
    if (UserId) {
      let userId = UserId;
      return {
        propertyId,
        language,
        userId,
      };
    } else
      return {
        propertyId,
        language,
      };
  }

  function handleSendMessage() {
    initiateChatChannel({
      userId1: token,
      userId2: _property.hostInfo.id,
      propertyId: _property.propertyId,
    }).then((_response) => {
      let path = "/Chats/" + _response.response.chat.channelId;
      history(path);
    });
  }

  function handleFav() {
    if (inFav === "0") {
      addFavourite({
        propertyId: _property.propertyId,
        userId: token,
      })
        .then((property) => {
          if (property.resCode === 0) {
            if (props.onChange) props.onChange();
            else setinFav("1");
          } else {
            toast.error(property.resStr);
          }
        })
        .catch((error) => {
          throw error;
        });
    } else {
      removeFavourite({
        propertyId: _property.propertyId,
        userId: token,
      })
        .then((property) => {
          if (property.resCode === 0) {
            if (props.onChange) props.onChange();
            else setinFav("0");
          } else {
            toast.error(property.resStr);
          }
        })
        .catch((error) => {
          throw error;
        });
    }
  }
  const renderVideo = (item) => {
    return (
      <video style={{ height: "93%", width: "100%", border: 0 }} controls>
        <source src={item.embedUrl} type='video/mp4' />
        Your browser does not support the video tag.
      </video>
    );
  };

  useEffect(() => {
    if (_property && _property.hostInfo) {
      getBrokerRegaInfo({
        licenseNumber: _property.hostInfo.advertiserNumber,
        // licenseType: "Bml"
      })
        .then((res) => {
          if (res.resCode === 0) {
            setLicense(res.response);
          }
        })
        .catch((err) => { });
    }
  }, [_property]);

  // function handleLike() {
  //   addOrChangeLikeStatusForProperty({
  //     propertyId: _property.propertyId,
  //     userId: token,
  //     isLiked: _property.isLiked === "1" ? "0" : "1"
  //   })
  //     .then((property) => {
  //       if (property.resCode === 0) {
  //         propertyActions.loadPropertyDetails(
  //           getProps(_property.propertyId, _language.language)
  //         );
  //       } else {
  //         toast.error(property.resStr);
  //       }
  //     })
  //     .catch((error) => {
  //       throw error;
  //     });

  // }

  const renderVideos = async () => {
    let temp = [];
    for (let item of videos) {
      let response = await fetch(item.video_url);
      let data = await response.blob();
      let file = new File([data], "test.mp4", { type: "video/mp4" });
      const snapshoter = new VideoSnapshot(file);
      const previewSrc = await snapshoter.takeSnapshot(10);

      temp.push({
        embedUrl: item.video_url,
        original: previewSrc || `${publicUrl}assets/img/icons/mp4-icon.png`,
        thumbnail: previewSrc || `${publicUrl}assets/img/icons/mp4-icon.png`,
        renderItem: renderVideo.bind(this),
        thumbnailClass: "video",
      });
    }
    setVideosToShow(temp);
  };
  useEffect(
    () => {
      if (
        videos &&
        videos?.length > 0 &&
        images.length > 0 &&
        videosToShow.length === 0
      ) {
        renderVideos();
      }
    }, // eslint-disable-next-line
    [videos, images]
  );

  const AllowedUsers = ["34882", "32955", "34296", "32387", "21532"];

  let HeaderTitle =
    _property.propertyTypeName +
    " " +
    (_property.listedFor === "2" ? t("ForSale") : t("ForRent"));
  if (_property.videoUrl && _property.videoUrl?.length > 0)
    videos = _property.videoUrl;

  if (_property.photos) {
    _photos = _property.photosNew.split(",");
  } else {
    _photos.push(
      publicUrl + "assets/img/defimgs/" + _property.propertyType + (DARK_TEMPLATES.includes(+templateId) && !isAdmin ? '-dark' : '') + ".svg"
    );
  }
  if (_property.floorPlans) FloorPlans = _property.floorPlans.split(",");
  _photos.forEach((element) => {
    images.push({
      original:
        element.indexOf("#") > -1
          ? element.substring(0, element.indexOf("#"))
          : element,
      thumbnail:
        element.indexOf("#") > -1
          ? element.substring(0, element.indexOf("#"))
          : element,
      description:
        element.indexOf("#") > -1
          ? element.substring(element.indexOf("#") + 1)
          : "",
    });
  });

  let data = "";
  if (
    _property?.additionalInfo?.filter((i) => i.key === "10")?.[0]?.valueText
  ) {
    data += `${t("RealEstateADLicenseNumber")}: ${_property.additionalInfo.filter((i) => i.key === "10")?.[0]?.valueText
      }\n\n`;
  }
  if (_property.rooms?.length > 0) {
    _property.rooms?.map((item) => (data += `${item.name}: ${item.value}\n`));
  }
  if (_property.features?.length > 0) {
    _property.features?.map(
      (item) =>
      (data += `${item.name}: ${item.key === "7"
        ? getFontspace(item.value)
        : Intl.NumberFormat("en").format(item.value) + " " + item.unit
        }\n`)
    );
  }
  if (_property.amenities?.length > 0) {
    data += `\n\n${t("Features")}:\n`;
    _property.amenities?.map((item) => (data += `${item.name}\n`));
  }

  //REGA rebuse

  const [checked, setChecked] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [REGASubjects, setREGASubjects] = useState([]);
  const [openMapModal, setOpenMapModal] = useState(false);
  const [openOwnerModal, setOpenOwnerModal] = React.useState(false);
  const [errors, setErrors] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [currentPlan, setCurrentPlan] = useState();

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleClickOpen = () => {
    if (checked.length > 0)
      toast.info(t("ThereIsPreviousReportOnThisRealEstateAD"));
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSendREGAIssue = () => {
    let checkedStr = "";

    checked.forEach((element) => {
      checkedStr = checkedStr + element + ",";
    });

    if (checkedStr.length > 1) {
      checkedStr = checkedStr.slice(0, -1);

      createREGAIssue({
        userId: token,
        language: i18n.language === 'ar' ? '0' : '1',
        propertyId: _property.propertyId,
        issues: checkedStr,
      }).then((property) => {
        if (property.resCode === 0) {
          toast.success(t("ComplaintSentSuccessfully"));
        } else {
          toast.error(property.resStr);
        }
      });
      setOpen(false);
    } else {
      toast.error(t("PleaseSelectAnItemFromTheList"));
    }
  };

  useEffect(() => {
    if (token)
      getUserPlan({ userId: token, language: i18n.language === 'ar' ? '0' : '1' }).then((result) => {
        if (result.resCode === 0) {
          setCurrentPlan(result?.response);
        }
      });
  }, [token, i18n]);

  useEffect(() => {
    if (token && _property.propertyId)
      getREGASubjects({
        userId: token,
        language: i18n.language === 'ar' ? '0' : '1',
        propertyId: _property.propertyId,
      }).then((property) => {
        if (property.resCode === 0) {
          setREGASubjects(property.response.subjects);
          setChecked(property.response.checked);
        }
      });
  }, [token, _property.createdAt, _property.propertyId, i18n]);

  function handleSubjectChanges(e) {
    setNewTicket({
      ...NewTicket,
      subjectId: e.target.value,
    });
  }

  function onSubmitNewTicket() {
    if (NewTicket.subjectId === "" || NewTicket.notes === "") {
      setErrors(true);
    } else {
      setIsSubmittingTicket(true);
      createATicket({
        ...NewTicket,
        userId: token,
        createdBy: token,
      })
        .then((_FeedBackInfo) => {
          if (_FeedBackInfo.resCode === 0) {
            toast.success(t("ReportedSuccessfully"));
            setShowReportModal(false);
            setNewTicket({
              ...NewTicket,
              subjectId: "",
              notes: "",
            });
          } else toast.error(_FeedBackInfo.resStr);
        })
        .finally(() => setIsSubmittingTicket(false));
    }
  }

  const renderLicense = (propertyObj) => {
    const license = propertyObj.hostInfo?.advertiserNumber;
    //propertyObj.additionalInfo.filter((i) => i.key === '21')?.[0];
    if (license || (License && License?.licenseNumber)) {
      return (
        <div className='media'>
          <div className='media-left'>
            <Summarize sx={{ color: "var(--main-color-one)" }} />
          </div>
          <div className='media-body'>
            <p>{t("FalLicense")}</p>
            {License && License?.id ? (
              <a
                target='_blank'
                style={{
                  textDecoration: "underline",
                  color: "var(--main-color-one)",
                }}
                rel='noreferrer'
                href={
                  propertyObj.hostInfo.accountType === "1"
                    ? INDIVIDUAL_LICENSE_URI + License?.id
                    : CORPORATE_LICENSE_URI + License?.id
                }>
                {License?.licenseNumber}
              </a>
            ) : (
              <span>{license}</span>
            )}
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };

  // useEffect(() => {
  //   if (_property) {
  //     const description = document.createElement("meta")
  //     const ogDescription = document.createElement("meta")
  //     const title = document.createElement("title")
  //     const ogTitle = document.createElement("meta")
  //     const ogImage = document.createElement("meta")

  //     ogDescription.setAttribute("name", "og:description")
  //     ogDescription.setAttribute("property", "og:description")
  //     ogDescription.setAttribute("content", `${_property.propertyTypeName} ${_property.listedFor === "2" ? t("ForSale") : t("ForRent")} - ${_property.address} - ${Intl.NumberFormat("en").format(_property.defaultPrice)} ريال ${_property.listedFor === "1" ? _property.defaultPriceType.name : ''}`)

  //     description.setAttribute("name", "description")
  //     description.setAttribute("property", "description")
  //     description.setAttribute("content", `${_property.propertyTypeName} ${_property.listedFor === "2" ? t("ForSale") : t("ForRent")} - ${_property.address} - ${Intl.NumberFormat("en").format(_property.defaultPrice)} ريال ${_property.listedFor === "1" ? _property.defaultPriceType.name : ''}`)

  //     title.appendChild(document.createTextNode('أماكن | '+ _property.propertyTypeName + ' '+ (_property.listedFor === "2" ? t("ForSale") : t("ForRent"))))

  //     ogTitle.setAttribute("name", 'أماكن | ' + _property.propertyTypeName + ' ' + (_property.listedFor === "2" ? t("ForSale") : t("ForRent")))
  //     ogTitle.setAttribute("property", "og:title")
  //     ogTitle.setAttribute("content", 'أماكن | '+ _property.propertyTypeName + ' '+ (_property.listedFor === "2" ? t("ForSale") : t("ForRent")))

  //     ogImage.setAttribute("name", _property?.photosNew?.split(",")?.[0] || (publicUrl + "assets/img/defimgs/" + _property.propertyType + ".svg"))
  //     ogImage.setAttribute("property", "og:image")
  //     ogImage.setAttribute("content", _property?.photosNew?.split(",")?.[0] || (publicUrl + "assets/img/defimgs/" + _property.propertyType + ".svg"))

  //     document.head.prepend(title)
  //     document.head.prepend(ogTitle)
  //     document.head.prepend(description)
  //     document.head.prepend(ogDescription)
  //     document.head.prepend(ogImage)
  //   }

  // },[_property]);

  return (isMap &&
    (_property.propertyId === propertyId ||
      _property.idToShare === propertyId) &&
    _code !== 1) ||
    (_code !== 1 && !isMap) ? (
    _code === 0 ? (
      <>
        <Helmet>
          <title>
            {t("Amakkn") + " | " +
              _property.propertyTypeName +
              " " +
              (_property.listedFor === "2" ? t("ForSale") : t("ForRent"))}
          </title>
          <meta
            property='og:title'
            content={
              t("Amakkn") + " | " +
              _property.propertyTypeName +
              " " +
              (_property.listedFor === "2" ? t("ForSale") : t("ForRent"))
            }
            data-react-helmet='true'
          />
          <meta
            name='description'
            content={`${_property.propertyTypeName} ${_property.listedFor === "2" ? t("ForSale") : t("ForRent")
              } - ${_property.address} - ${Intl.NumberFormat("en").format(
                _property.defaultPrice
              )} ريال ${_property.listedFor === "1" ? _property.defaultPriceType.name : ""
              }`}
            data-react-helmet='true'
          />
          <meta
            property='og:description'
            content={`${_property.propertyTypeName} ${_property.listedFor === "2" ? t("ForSale") : t("ForRent")
              } - ${_property.address} - ${Intl.NumberFormat("en").format(
                _property.defaultPrice
              )} ريال ${_property.listedFor === "1" ? _property.defaultPriceType.name : ""
              }`}
            data-react-helmet='true'
          />
          <meta
            property='og:url'
            content={`${window.location.origin}/property-details/${_property.idToShare}`}
            data-react-helmet='true'
          />
          <meta property='og:type' content='article' data-react-helmet='true' />
          <meta
            property='og:image'
            content={
              _property?.photosNew?.split(",")?.[0] ||
              publicUrl +
              "assets/img/defimgs/" +
              _property.propertyType + (DARK_TEMPLATES.includes(+templateId) && !isAdmin ? '-dark' : '') +
              ".svg"
            }
            data-react-helmet='true'
          />
          <meta
            name='twitter:card'
            content='summary_large_image'
            data-react-helmet='true'
          />
          <meta
            name='twitter:title'
            content={
              t("Amakkn") + " | " +
              _property.propertyTypeName +
              " " +
              (_property.listedFor === "2" ? t("ForSale") : t("ForRent"))
            }
            data-react-helmet='true'
          />
          <meta
            name='twitter:description'
            content={`${_property.propertyTypeName} ${_property.listedFor === "2" ? t("ForSale") : t("ForRent")
              } - ${_property.address} - ${Intl.NumberFormat("en").format(
                _property.defaultPrice
              )} ${t('SAR')} ${_property.listedFor === "1" ? _property.defaultPriceType.name : ""
              }`}
            data-react-helmet='true'
          />
          <meta
            name='twitter:image'
            content={
              _property?.photosNew?.split(",")?.[0] ||
              publicUrl +
              "assets/img/defimgs/" +
              _property.propertyType + (DARK_TEMPLATES.includes(+templateId) && !isAdmin ? '-dark' : '') +
              ".svg"
            }
            data-react-helmet='true'
          />
        </Helmet>

        <div
          className={`property-details-area ${isMap
            ? "property-details-area2 p-0"
            : isAdmin
              ? "no-padding admin"
              : ""
            }`}
          key={_property.propertyId}>
          {/* <div className={isMap ? "bg-gray " : "bg-gray pd-top-100 pd-bottom-90"}> */}
          <div className='row'>
            {/* <div className={isMap ? "col-12" : "col-xl-9 col-lg-8"}> */}
            <div className='col-12'>
              <div className='property-details-slider-info'>
                {isAdmin ? (
                  <div className='col-12 p-0 m-0'>
                    <Link
                      className='mb-4 primary-btn py-2 px-3 '
                      style={{ width: "fit-content", marginRight: "-10px" }}
                      to='/admin/property/my-listings'>
                      {t("BackToRealEstateADsPage")}
                    </Link>
                  </div>
                ) : (
                  <></>
                )}
                <div className='row fixed-scroll'>
                  <div className={`col-12 col-lg-8 ${i18n.language === "ar" ? 'pl-lg-5' : 'pr-lg-5'}`}>
                    <div className='row '>
                      <div
                        dir='ltr'
                        className='col-12 property-details-slider p-0'>
                        <ImageGallery
                          items={[...images, ...videosToShow]}
                          showPlayButton={false}
                          showThumbnails={
                            [...images, ...videosToShow].length > 1
                          }
                        />
                      </div>
                      <div
                        className='col-12 property-header'
                        style={{
                          paddingTop:
                            _property?.isFeatured === "1" ? "35px" : "25px",
                          marginTop: images.length > 1 ? "auto" : "20px",
                        }}>
                        {_property?.isFeatured === "1" && (
                          <div className='featured-ribbon'><span>{t("FeaturedAD")}</span></div>
                        )}
                        <h3>
                          {propertyLicenseNumber &&
                            propertyLicenseNumber !== "لا" ? (
                            <>
                              <span>
                                {Intl.NumberFormat("en").format(
                                  _property.defaultPrice
                                )}{" "}
                                {t("SAR")}
                              </span>
                              {_property.listedFor === "1" && (
                                <span style={{ fontSize: 20 }}>
                                  &nbsp;({_property.defaultPriceType.name})
                                </span>
                              )}
                            </>
                          ) : (
                            HeaderTitle
                          )}
                        </h3>
                        <div className='btns-wrapper'>
                          {// ["12818", "161438", "155668"].includes(UserId) ||
                            _property.hostInfo.id === UserId &&
                            (currentPlan?.canExportAdPhoto === "1" || currentPlan?.isTryWlMode === "1") && (
                              <PropertyPapper data={_property} />
                            )}

                          {_property.hostInfo.id !== UserId &&
                            vendor === "amakkn" && (
                              <>
                                {token ? (
                                  <button
                                    className={`btn btn-white-bg btn-info-bg text-info info-border ${inFav === "0"
                                      ? "fav-btn"
                                      : "fav-btn clicked"
                                      }`}
                                    onClick={(e) => {
                                      handleFav();
                                    }}>
                                    <span>
                                      {inFav === "0" ? t("Save") : t("Saved")}
                                    </span>
                                    {inFav === "0" ? (
                                      <FavoriteBorderIcon
                                        sx={{
                                          stroke: "#243ead",
                                          "&:hover": {
                                            fill: "#fff",
                                            stroke: "#fff",
                                          },
                                        }}
                                      />
                                    ) : (
                                      <FavoriteIcon sx={{ fill: "#fff" }} />
                                    )}
                                  </button>
                                ) : (
                                  <LoginPopup
                                    setToken={setToken}
                                    setUserId={setUserId}
                                    btnTyp='3'
                                    text={t("Save")}
                                    extraClasses='btn-white-bg btn-info-bg text-info info-border'
                                    icon={
                                      <FavoriteBorderIcon
                                        sx={{
                                          stroke: "#243ead",
                                          "&:hover": {
                                            fill: "#fff",
                                            stroke: "#fff",
                                          },
                                        }}
                                      />
                                    }
                                  />
                                )}
                                {/* {
                              token ?
                                <button className={`btn ${_property.isLiked === "1" ? 'fav-btn clicked' : 'fav-btn'}`}
                                  onClick={(e) => {
                                    handleLike();
                                  }}>
                                  <span> {_property.numberOfLikes} </span>
                                  {_property.isLiked === "1" ? <ThumbUpAltIcon /> : <ThumbUpOffAltIcon />}
                                  

                                </button>
                                :
                                <LoginPopup
                                  setToken={setToken}
                                  setUserId={setUserId}
                                  btnTyp="3"
                                  text={_property.numberOfLikes}
                                  icon={<ThumbUpOffAltIcon />}
                                />
                            } */}
                              </>
                            )}
                          <button
                            className='btn fav-btn btn-white-bg btn-info-bg text-info info-border'
                            onClick={() => setOpenShareModal(true)}>
                            <span>{t("Share")}</span>
                            <ShareIcon />
                          </button>
                          <Dialog
                            onClose={() => setOpenShareModal(false)}
                            className='custom-dialog no-padding full-screen2'
                            open={openShareModal}>
                            <DialogContent
                              sx={{ padding: 0, overflow: "hidden" }}>
                              <div className='modal2 '>
                                <IconButton
                                  onClick={() => setOpenShareModal(false)}
                                  sx={{
                                    color: (theme) => theme.palette.grey[500],
                                  }}>
                                  <CloseIcon />
                                </IconButton>
                                <div className='social-modal'>
                                  <div className='social-items-wrapper'>
                                    <ClickAwayListener
                                      onClickAway={() => setOpenTooltip(false)}>
                                      <Tooltip
                                        PopperProps={{ disablePortal: true }}
                                        sx={{ zIndex: "99999999999999999" }}
                                        onClose={() => setOpenTooltip(false)}
                                        open={openTooltip}
                                        disableHoverListener
                                        placement='left'
                                        title={t("CopiedSuccessfully")}>
                                        <div
                                          className='social-item'
                                          onMouseLeave={() =>
                                            setOpenTooltip(false)
                                          }
                                          onClick={() => {
                                            navigator?.clipboard?.writeText(
                                              `${window.location.origin}/property-details/${_property.idToShare}`
                                            );
                                            setOpenTooltip(true);
                                          }}>
                                          <ContentCopyIcon />
                                          <span>{t("CopyLink")}</span>
                                        </div>
                                      </Tooltip>
                                    </ClickAwayListener>

                                    <div
                                      className='social-item'
                                      onClick={() => {
                                        window.open(
                                          encodeURI(
                                            `${isLargeScreen ? `https://api.whatsapp.com/` : `whatsapp://`}send?text=${HeaderTitle}\nالعنوان: ${_property.address.trim()}\n${propertyLicenseNumber &&
                                            propertyLicenseNumber !== "لا" &&
                                            `${t("Price")}: ${Intl.NumberFormat(
                                              "en"
                                            ).format(
                                              _property.defaultPrice
                                            )} ${t("SAR")} ${_property.listedFor === "1"
                                              ? _property.defaultPriceType
                                                .name
                                              : ""
                                            }`
                                            }\n\n${data}\n\n${window.location.origin
                                            }/property-details/${_property.idToShare
                                            }`
                                          )
                                        );
                                      }}>
                                      <WhatsAppIcon />
                                      <span>{t("WhatsApp")}</span>
                                    </div>
                                    <div
                                      className='social-item'
                                      onClick={() => {
                                        window.open(
                                          "http://facebook.com/sharer/sharer.php?u=" +
                                          encodeURIComponent(
                                            `${window.location.origin}/${_property.idToShare}`
                                          ),
                                          "",
                                          "left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
                                        );
                                      }}>
                                      <FacebookIcon />
                                      <span>{t("Facebook")}</span>
                                    </div>
                                    <div
                                      className='social-item'
                                      onClick={() => {
                                        window.open(
                                          "https://twitter.com/intent/tweet?text=" +
                                          encodeURIComponent(
                                            `${window.location.origin}/${_property.idToShare}`
                                          )
                                        );
                                      }}>
                                      <Twitter medium />
                                      <span>X</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </DialogContent>
                          </Dialog>
                        </div>
                      </div>
                      <div className='col-12 property-sub-header'>
                        <div style={{ maxWidth: "calc(100% - 80px)" }}>
                          {propertyLicenseNumber &&
                            propertyLicenseNumber !== "لا" && (
                              <h3>{HeaderTitle}</h3>
                            )}
                          <p
                            className='d-flex align-items-start mt-3'
                            style={{ gap: 8 }}>
                            <RoomIcon sx={{ color: "var(--main-color-one)" }} />{" "}
                            <span>{_property.address}</span>
                          </p>
                          {_property?.additionalInfo?.filter(
                            (i) => i.key === "10"
                          )?.[0] &&
                            _property?.additionalInfo?.filter(
                              (i) => i.key === "10"
                            )?.[0]?.valueText !== "لا" && (
                              <p
                                className='d-flex align-items-start mt-3'
                                style={{ gap: 8 }}>
                                <DescriptionIcon
                                  sx={{ color: "var(--main-color-one)" }}
                                />
                                <span>
                                  {
                                    _property.additionalInfo.filter(
                                      (i) => i.key === "10"
                                    )?.[0]?.name
                                  }
                                  :&nbsp;
                                  <a className='custom-span' href={
                                    _property.additionalInfo.filter(
                                      (i) => i.key === "18"
                                    )?.[0]?.valueText
                                  }
                                    target='_blank'
                                    style={{ textDecoration: 'underline' }}
                                    rel='noreferrer'>
                                    {
                                      _property.additionalInfo.filter(
                                        (i) => i.key === "10"
                                      )?.[0]?.valueText
                                    }
                                  </a>
                                </span>
                              </p>
                            )}

                          {_property.additionalInfo.filter(
                            (i) => i.key === "34"
                          )?.[0]?.valueText !== "لا" && (
                              <p
                                className='d-flex align-items-start mt-3'
                                style={{ gap: 8 }}>
                                {/* {_property.additionalInfo.filter((i) => i.key === '34')?.[0]?.valueText === 'الهيئة العامة للعقار' ? (
                                <img src={publicUrl + 'assets/img/logo/rega-logo.png'}
                                  style={{
                                    width: '128px',
                                    height: '40px'
                                  }}
                                  alt="الهيئة العامة للعقار" />
                              ) : (
                                <> */}
                                <CampaignIcon
                                  sx={{ color: "var(--main-color-one)" }}
                                />
                                <span>
                                  {_property.additionalInfo.filter(
                                    (i) => i.key === "34"
                                  )?.[0]?.name + ": "}
                                  <span className='custom-span'>
                                    {
                                      _property.additionalInfo.filter(
                                        (i) => i.key === "34"
                                      )?.[0]?.valueText
                                    }
                                  </span>
                                </span>
                                {/* </>
                              )} */}
                              </p>
                            )}
                          <p
                            className='d-flex align-items-start mt-3'
                            style={{ gap: 8 }}>
                            <CalendarMonthIcon
                              sx={{ color: "var(--main-color-one)" }}
                            />
                            <span>
                              {t("TheADHasBeenPublished")}
                              <span className='custom-span'>
                                {" "}
                                {moment(new Date(_property.createdAt)).locale('en').format('YYYY-MM-DD')}
                              </span>
                            </span>
                          </p>

                          <p
                            className='d-flex align-items-start mt-3'
                            style={{ gap: 8 }}>
                            <EditCalendarIcon
                              sx={{ color: "var(--main-color-one)" }}
                            />
                            <span>
                              {t("TheADHasBeenUpdated")}
                              <span className='custom-span'>
                                {" "}
                                {moment(new Date(_property.updatedAt)).locale('en').format('YYYY-MM-DD')}
                              </span>
                            </span>
                          </p>
                        </div>
                        <div>
                          {_property.additionalInfo.filter(
                            (i) => i.key === "18"
                          )?.[0]?.valueText !== "لا" && (
                              <>
                                <div
                                  style={{
                                    height: "auto",
                                    maxWidth: 75,
                                    width: "100%",
                                    border: "4px solid #e9e8ea",
                                    borderRadius: "12px",
                                    padding: "8px 5px 0",
                                  }}>
                                  {!_property.additionalInfo
                                    .filter((i) => i.key === "18")?.[0]
                                    ?.valueText?.includes("amakkn") ? (
                                    <a
                                      href={
                                        _property.additionalInfo.filter(
                                          (i) => i.key === "18"
                                        )?.[0]?.valueText
                                      }
                                      target='_blank'
                                      rel='noreferrer'>
                                      <QRCode
                                        size={256}
                                        style={{
                                          height: "auto",
                                          maxWidth: "100%",
                                          width: "100%",
                                        }}
                                        value={
                                          _property.additionalInfo.filter(
                                            (i) => i.key === "18"
                                          )?.[0]?.valueText
                                        }
                                        viewBox={`0 0 256 256`}
                                      />
                                    </a>
                                  ) : (
                                    <img
                                      src={
                                        _property.additionalInfo.filter(
                                          (i) => i.key === "18"
                                        )?.[0]?.valueText
                                      }
                                      style={{
                                        height: "auto",
                                        maxWidth: "100%",
                                        width: "100%",
                                      }}
                                      alt={t("QRCode")}
                                    />
                                  )}
                                </div>
                                <span
                                  className={`d-block badge mt-2 ${["0", "3"].includes(_property.status)
                                    ? "warning"
                                    : "success"
                                    }`}>
                                  {["0", "2", "3"].includes(_property.status) ? t("InactiveAD") : t("ActiveAD")}
                                </span>
                              </>
                            )}
                        </div>
                      </div>

                      <div className='row property-inner-details'>
                        {propertyLicenseNumber &&
                          propertyLicenseNumber !== "لا" &&
                          _property.description && (
                            <div className='col-12 mt-0'>
                              <h4>{t("PropertyDescription")}</h4>
                              <p
                                className='text-more'
                                style={{ whiteSpace: "pre-line" }}>
                                {expandedTheDesc
                                  ? _property.description
                                  : _property.description.length > 120
                                    ? _property.description.substr(0, 120) +
                                    "... "
                                    : _property.description}
                                {_property.description.length > 120 && (
                                  <button
                                    onClick={() =>
                                      setExpandedTheDesc(!expandedTheDesc)
                                    }>
                                    {expandedTheDesc ? t("ShowLess") : t("ShowMore")}
                                  </button>
                                )}
                              </p>
                            </div>
                          )}

                        {(propertyLicenseNumber &&
                          propertyLicenseNumber !== "لا") ||
                          ((!propertyLicenseNumber ||
                            propertyLicenseNumber === "لا") &&
                            _property.hostInfo.id === UserId) ? (
                          <div className='d-block d-lg-none col-12 mb-4 mobile widget-owner-info'>
                            {token ? (
                              <div className='owner-info'>
                                <Link
                                  to={{
                                    pathname: `${isAdmin ? "/admin" : ""}${_property.hostInfo.accountType === "1"
                                      ? "/user-profile/"
                                      : "/company-profile/"
                                      }${_property.hostInfo.idToShare}`,
                                  }}
                                  state={{ userId: _property.hostInfo.id }}>
                                  <div className='thumb'>
                                    {_property.hostInfo.userType === "5" ? (
                                      <Badge
                                        overlap='circular'
                                        anchorOrigin={{
                                          vertical: "bottom",
                                          horizontal: "left",
                                        }}
                                        badgeContent={
                                          <Link
                                            to={`${isAdmin ? "/admin" : ""
                                              }/company-profile/${_property.hostInfo.idToShare
                                              }`}>
                                            <SmallAvatar
                                              alt='companyAvatar'
                                              src={_property.hostInfo.avatar}
                                            />
                                          </Link>
                                        }>
                                        <Avatar
                                          sx={{
                                            width: 75,
                                            height: 75,
                                            bgcolor: _property.hostInfo
                                              .companyAvatar
                                              ? "#fff"
                                              : "var(--main-color-one)",
                                          }}
                                          alt='image1'
                                          src={_property.hostInfo.companyAvatar}
                                        />
                                      </Badge>
                                    ) : (
                                      <Avatar
                                        sx={{
                                          width: 75,
                                          height: 75,
                                          bgcolor: _property.hostInfo.avatar
                                            ? "#fff"
                                            : "var(--main-color-one)",
                                        }}
                                        src={_property.hostInfo.avatar}
                                        alt='image1'
                                      />
                                    )}
                                  </div>
                                </Link>

                                <div className='details'>
                                  <Link
                                    to={{
                                      pathname: `${isAdmin ? "/admin" : ""}${_property.hostInfo.accountType === "1"
                                        ? "/user-profile/"
                                        : "/company-profile/"
                                        }${_property.hostInfo.userType === "5"
                                          ? _property.hostInfo.companyIdToShare
                                          : _property.hostInfo.idToShare
                                        }`,
                                    }}
                                    state={{ userId: _property.hostInfo.id }}>
                                    <h5>
                                      {_property.hostInfo.userType === "5"
                                        ? _property.hostInfo.companyName
                                        : _property.hostInfo.name}
                                    </h5>
                                  </Link>
                                  <span className='designation'>
                                    {_property.hostInfo.userType === "5" ? (
                                      <Link
                                        to={`${isAdmin ? "/admin" : ""
                                          }/company-profile/${_property.hostInfo.idToShare
                                          }`}>
                                        {`${t("Agent")}: ` +
                                          _property.hostInfo.name}
                                      </Link>
                                    ) : (
                                      resolveUserTypeName(
                                        _property.hostInfo.userType
                                      )
                                    )}
                                    {_property.hostInfo.isUserVerified >= 2 && (
                                      <img
                                        src={
                                          publicUrl +
                                          "assets/img/icons/verfiy.svg"
                                        }
                                        alt='image2'
                                        style={{ margin: "0 2px" }}
                                      />
                                    )}
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <div
                                className='owner-info'
                                style={{ cursor: "pointer" }}>
                                <LoginPopup
                                  setToken={setToken}
                                  setUserId={setUserId}
                                  path={`${isAdmin ? "/admin" : ""}${_property.hostInfo.accountType === "1"
                                    ? "/user-profile/"
                                    : "/company-profile/"
                                    }${_property.hostInfo.userType === "5"
                                      ? _property.hostInfo.companyIdToShare
                                      : _property.hostInfo.idToShare
                                    }`}
                                  state={{ userId: _property.hostInfo.id }}>
                                  <div className='thumb'>
                                    {_property.hostInfo.userType === "5" ? (
                                      <Badge
                                        overlap='circular'
                                        anchorOrigin={{
                                          vertical: "bottom",
                                          horizontal: "left",
                                        }}
                                        badgeContent={
                                          <Link
                                            to={`${isAdmin ? "/admin" : ""
                                              }/company-profile/${_property.hostInfo.idToShare
                                              }`}>
                                            <SmallAvatar
                                              alt='companyAvatar'
                                              src={_property.hostInfo.avatar}
                                            />
                                          </Link>
                                        }>
                                        <Avatar
                                          sx={{
                                            width: 75,
                                            height: 75,
                                            bgcolor: _property.hostInfo
                                              .companyAvatar
                                              ? "#fff"
                                              : "var(--main-color-one)",
                                          }}
                                          alt='image1'
                                          src={_property.hostInfo.companyAvatar}
                                        />
                                      </Badge>
                                    ) : (
                                      <Avatar
                                        sx={{
                                          width: 75,
                                          height: 75,
                                          bgcolor: _property.hostInfo.avatar
                                            ? "#fff"
                                            : "var(--main-color-one)",
                                        }}
                                        src={_property.hostInfo.avatar}
                                        alt='image1'
                                      />
                                    )}
                                  </div>
                                </LoginPopup>

                                <div className='details'>
                                  <LoginPopup
                                    setToken={setToken}
                                    setUserId={setUserId}
                                    path={`${isAdmin ? "/admin" : ""}${_property.hostInfo.accountType === "1"
                                      ? "/user-profile/"
                                      : "/company-profile/"
                                      }${_property.hostInfo.userType === "5"
                                        ? _property.hostInfo.companyIdToShare
                                        : _property.hostInfo.idToShare
                                      }`}
                                    state={{ userId: _property.hostInfo.id }}>
                                    <h5>
                                      {_property.hostInfo.userType === "5"
                                        ? _property.hostInfo.companyName
                                        : _property.hostInfo.name}
                                    </h5>
                                  </LoginPopup>
                                  <span className='designation'>
                                    {_property.hostInfo.userType === "5" ? (
                                      <Link
                                        to={`${isAdmin ? "/admin" : ""
                                          }/company-profile/${_property.hostInfo.idToShare
                                          }`}>
                                        {`${t("Agent")}: ` +
                                          _property.hostInfo.name}
                                      </Link>
                                    ) : (
                                      resolveUserTypeName(
                                        _property.hostInfo.userType
                                      )
                                    )}
                                    {_property.hostInfo.isUserVerified >= 2 && (
                                      <img
                                        src={
                                          publicUrl +
                                          "assets/img/icons/verfiy.svg"
                                        }
                                        alt='image2'
                                        style={{ margin: "0 2px" }}
                                      />
                                    )}
                                  </span>
                                </div>
                              </div>
                            )}
                            <Dialog
                              fullWidth={true}
                              maxWidth={"sm"}
                              open={openOwnerModal}
                              sx={{
                                "& .MuiPaper-root": {
                                  margin: 0,
                                  width: "calc(100% - 20px)",
                                },
                              }}
                              className='custom-dialog model widget-owner-info '
                              onClose={() => setOpenOwnerModal(false)}>
                              <DialogTitle>
                                <div className='dialog-head contact-info p-0'>
                                  <h6 className='mb-0'>{t("AdvertiserInfo")}</h6>
                                  <div className='dialog-actions close-only'>
                                    <IconButton
                                      aria-label='close'
                                      onClick={() => setOpenOwnerModal(false)}
                                      sx={{
                                        color: (theme) =>
                                          theme.palette.grey[500],
                                      }}>
                                      <CloseIcon />
                                    </IconButton>
                                  </div>
                                </div>
                              </DialogTitle>
                              <DialogContent>
                                <div className='contact-info'>
                                  {renderLicense(_property)}

                                  {/* {_property.hostInfo.companyId && _property.hostInfo.userType === '5' && (
                                    <div className='media'>
                                      <div className='media-left'>
                                        <BusinessIcon sx={{ color: 'var(--main-color-one)' }} />
                                      </div>
                                      <div className='media-body'>
                                        <p>المنشأة العقاريّة التابع لها</p>
                                        <Link to={(isAdmin ? '/admin' : '') + '/company-profile/' + _property.hostInfo.companyIdToShare}>
                                          {_property.hostInfo.companyName}
                                        </Link>
                                      </div>
                                    </div>
                                  )} */}

                                  {/* {_property.hostInfo.address && (
                                  <div className='media'>
                                    <div className='media-left'>
                                      <Marker />
                                    </div>
                                    <div className='media-body'>
                                      <p>{t("Address")}</p>
                                      <span>{_property.hostInfo.address}</span>
                                    </div>
                                  </div>
                                )} */}
                                  {propertyLicenseNumber &&
                                    propertyLicenseNumber !== "لا" && (
                                      <div className='media'>
                                        <div className='media-left'>
                                          <Tel />
                                        </div>
                                        <div className='media-body'>
                                          <p>{t("PhoneNumber")}</p>
                                          <a
                                              style={{ textDecoration: 'underline' }}
                                              rel="noreferrer"
                                            target={isLargeScreen ? '_blank' : "_self"}
                                            href={
                                              "tel:" +
                                              _property.hostInfo.countryCode +
                                              _property.hostInfo.phone
                                            }>
                                            0{_property.hostInfo.phone}
                                          </a>
                                          {_property?.additionalInfo?.filter(
                                            (i) => i.key === "23"
                                          )?.[0]?.valueText &&
                                            _property?.additionalInfo?.filter(
                                              (i) => i.key === "23"
                                            )?.[0]?.valueText !== "لا" &&
                                            _property?.additionalInfo
                                              ?.filter((i) => i.key === "23")?.[0]
                                              ?.valueText?.indexOf(
                                                _property?.hostInfo?.phone?.substring(
                                                  1
                                                )
                                              ) === -1 ? (
                                            <>
                                              &nbsp; - &nbsp;
                                              <a
                                                    style={{ textDecoration: 'underline' }}
                                                    rel="noreferrer"
                                                    target={isLargeScreen ? '_blank' : "_self"}
                                                href={`tel:${_property?.additionalInfo?.filter(
                                                  (i) => i.key === "23"
                                                )?.[0]?.valueText
                                                  }`}>
                                                {
                                                  _property?.additionalInfo?.filter(
                                                    (i) => i.key === "23"
                                                  )?.[0]?.valueText
                                                }
                                              </a>
                                            </>
                                          ) : null}
                                        </div>
                                      </div>
                                    )}

                                  {_property.hostInfo.unifiedNumber && (
                                    <div className='media'>
                                      <div className='media-left'>
                                        <Tel />
                                      </div>
                                      <div className='media-body'>
                                        <p>{t("UnifiedContactNumber")}</p>
                                        <span>
                                          {_property.hostInfo.unifiedNumber}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                  {_property.hostInfo.email && (
                                    <div className='media'>
                                      <div className='media-left'>
                                        <Mail />
                                      </div>
                                      <div className='media-body'>
                                        <p>{t("Email")}</p>
                                        <a
                                          style={{ textDecoration: 'underline' }}
                                          href={
                                            "mailto:" + _property.hostInfo.email
                                          }>
                                          {_property.hostInfo.email}
                                        </a>
                                      </div>
                                    </div>
                                  )}

                                  {_property.hostInfo.website && vendor === "amakkn" && (
                                    <div className='media'>
                                      <div className='media-left'>
                                        <Mail />
                                      </div>
                                      <div className='media-body'>
                                        <p>{t("Website")}</p>
                                        <a
                                          target='_blank'
                                          rel='noreferrer'
                                          style={{ textDecoration: 'underline' }}
                                          href={
                                            "https://" +
                                            _property.hostInfo.website
                                          }>
                                          {_property.hostInfo.website}
                                        </a>
                                      </div>
                                    </div>
                                  )}

                                  {propertyLicenseNumber &&
                                    propertyLicenseNumber === "لا" && (
                                      <>
                                        <Alert
                                          severity={"warning"}
                                          className='info-alert mb-3'
                                          sx={{
                                            "& .MuiAlert-message": {
                                              width: "100%",
                                            },
                                          }}>
                                          <AlertTitle
                                            sx={{
                                              width: "100%",
                                              textAlign: "right",
                                              display: "flex",
                                              flexWrap: "wrap",
                                              justifyContent: "space-between",
                                            }}>
                                            {t("WaitingForTheADLicenseNumber")}
                                          </AlertTitle>
                                          {t("AccordingToTheNewRulesAndRegulations")}
                                          <a
                                            rel='noreferrer'
                                            style={{
                                              textDecoration: "underline",
                                              fontWeight: 500,
                                            }}
                                            href='https://eservices.rega.gov.sa/'
                                            target='_blank'>
                                            {" "}
                                            {t("ElectronicServicesOfTheGeneralAuthorityForRealEstate")}
                                          </a>
                                        </Alert>
                                      </>
                                    )}

                                  {AllowedUsers.includes(UserId) &&
                                    REGASubjects.length > 0 && (
                                      <div className='text-align-last-center'>
                                        <Button
                                          className='btn primary-btn danger-btn mt-3 px-3'
                                          onClick={handleClickOpen}>
                                          {_property.REGAButtonTitle}
                                        </Button>
                                      </div>
                                    )}
                                </div>
                              </DialogContent>
                            </Dialog>
                            {_property.hostInfo.id !== UserId ? (
                              <div className='btns-wrapper'>
                                {vendor === "amakkn" ? (
                                  token ? UserId === "1" ? _property.status !== "2" ? (
                                    <Link
                                      className='btn primary-btn btn-blue px-3'
                                      to={`${isAdmin ||
                                        window.location.href.includes(
                                          "properties"
                                        )
                                        ? "/admin/property"
                                        : ""
                                        }/edit-property/${_property.propertyId}`}>
                                      {t("EditAD")}
                                    </Link>
                                  ) : <button
                                    className='btn primary-btn'
                                    onClick={(e) => handleSendMessage()}>
                                    {t("SendMessageToAdvertiser")}
                                  </button> : (
                                    <button
                                      className='btn primary-btn'
                                      onClick={(e) => handleSendMessage()}>
                                      {t("SendMessageToAdvertiser")}
                                    </button>
                                  ) : (
                                    <LoginPopup
                                      setToken={setToken}
                                      setUserId={setUserId}
                                      ToChat={true}
                                      btnTyp='1'
                                      userId2={_property.hostInfo.id}
                                      propertyId={_property.propertyId}
                                      settext={t("SendMessageToAdvertiser")}
                                    />
                                  )
                                ) : (
                                  <button
                                    className='btn primary-btn'
                                    onClick={() =>
                                      window.open(
                                        `https://www.amakkn${window.location.origin.includes(
                                          "amakkncompany.com"
                                        ) ||
                                          process.env.REACT_APP_API_URL?.includes(
                                            "devsa2"
                                          )
                                          ? ".org"
                                          : ".com"
                                        }/property-details/${_property.idToShare
                                        }#send-message`
                                      )
                                    }>
                                    {t("SendMessageToAdvertiser")}
                                  </button>
                                )}
                                <button
                                  className='btn outlined-btn'
                                  onClick={() => setOpenOwnerModal(true)}>
                                  {t("MoreDetails")}
                                </button>
                              </div>
                            ) : (
                              <div className='btns-wrapper'>
                                {_property.status !== "2" && (
                                  <Link
                                    className='btn primary-btn btn-blue px-3'
                                    to={`${isAdmin ||
                                      window.location.href.includes(
                                        "properties"
                                      )
                                      ? "/admin/property"
                                      : ""
                                      }/edit-property/${_property.propertyId}`}>
                                    {t("EditAD")}
                                  </Link>
                                )}
                                <button
                                  className='btn outlined-btn'
                                  onClick={() => setOpenOwnerModal(true)}>
                                  {t("MoreDetails")}
                                </button>
                              </div>
                            )}
                            {(!propertyLicenseNumber ||
                              propertyLicenseNumber === "لا") && (
                                <Alert
                                  severity={"warning"}
                                  className='info-alert mt-3'
                                  sx={{
                                    "& .MuiAlert-message": { width: "100%" },
                                  }}>
                                  <AlertTitle
                                    sx={{
                                      width: "100%",
                                      textAlign: "right",
                                      display: "flex",
                                      flexWrap: "wrap",
                                      justifyContent: "space-between",
                                    }}>
                                    {t("WaitingForTheADLicenseNumber")}
                                  </AlertTitle>
                                  {t("AccordingToTheNewRulesAndRegulations")}
                                  <a
                                    rel='noreferrer'
                                    style={{
                                      textDecoration: "underline",
                                      fontWeight: 500,
                                    }}
                                    href='https://eservices.rega.gov.sa/'
                                    target='_blank'>
                                    {" "}
                                    {t("ElectronicServicesOfTheGeneralAuthorityForRealEstate")}
                                  </a>
                                </Alert>
                              )}
                          </div>
                        ) : (
                          <div className='d-block d-lg-none col-12'>
                            {(!propertyLicenseNumber ||
                              propertyLicenseNumber === "لا") && (
                                <Alert
                                  severity={"warning"}
                                  className='info-alert'
                                  sx={{
                                    "& .MuiAlert-message": { width: "100%" },
                                  }}>
                                  <AlertTitle
                                    sx={{
                                      width: "100%",
                                      textAlign: "right",
                                      display: "flex",
                                      flexWrap: "wrap",
                                      justifyContent: "space-between",
                                    }}>
                                    {t("WaitingForTheADLicenseNumber")}
                                  </AlertTitle>
                                  {t("AccordingToTheNewRulesAndRegulations")}
                                  <a
                                    rel='noreferrer'
                                    style={{
                                      textDecoration: "underline",
                                      fontWeight: 500,
                                    }}
                                    href='https://eservices.rega.gov.sa/'
                                    target='_blank'>
                                    {" "}
                                    {t("ElectronicServicesOfTheGeneralAuthorityForRealEstate")}
                                  </a>
                                </Alert>
                              )}
                          </div>
                        )}

                        <div className='col-12'>
                          <h4>{t("PropertyDetails")}</h4>
                          <div className='property-info'>
                            <div className='row p-0 m-0'>
                              {_property.rooms.map((item) => (
                                <div
                                  className='col-sm-6 col-lg-4 box'
                                  key={item.key}>
                                  <div className='icon'>
                                    {renderRoomIcon(item.key)}
                                  </div>
                                  <div className='info'>
                                    <h5>{item.name}</h5>
                                    <span>
                                      {item.key === "11"
                                        ? item.value === "0"
                                          ? t("GroundFloor")
                                          : item.value
                                        : item.value}
                                    </span>
                                  </div>
                                </div>
                              ))}
                              {_property.features.map((item) => item.key !== '6' && (
                                <div
                                  className='col-sm-6 col-lg-4 box'
                                  key={item.key}>
                                  <div className='icon'>
                                    {renderFeatureIcon(item.key)}
                                  </div>
                                  <div className='info'>
                                    <h5>{item.name}</h5>
                                    <span>
                                      <i className='fa' />
                                      {item.key === "7"
                                        ? getFontspace(item.value)
                                        : Intl.NumberFormat("en").format(item.value) + " " + item.unit}
                                    </span>
                                  </div>
                                </div>
                              ))}
 
                              {_property?.propertyType !== '1' && _property?.additionalInfo?.filter(
                                (i) => i.key === "60"
                              )?.[0] && <div
                                className='col-sm-6 col-lg-4 box'
                                key={60}>
                                  <div className='icon'>
                                    {renderFeatureIcon('6')}
                                  </div>
                                  <div className='info'>
                                    <h5>{_property?.additionalInfo?.filter(
                                      (i) => i.key === "60"
                                    )?.[0]?.name}</h5>
                                    <span>
                                      {_property?.additionalInfo?.filter(
                                        (i) => i.key === "60"
                                      )?.[0]?.valueText}
                                    </span>
                                  </div>
                                </div>}
                                
                              <div className='col-sm-6 col-lg-4 box'>
                                <div className='icon'>
                                  <BubbleChartIcon />
                                </div>
                                <div className='info'>
                                  <h5>
                                    {
                                      _property?.additionalInfo?.filter(
                                        (i) => i.key === "22"
                                      )?.[0]?.name
                                    }
                                  </h5>
                                  <span>
                                    {
                                      _property?.additionalInfo?.filter(
                                        (i) => i.key === "22"
                                      )?.[0]?.valueText
                                    }
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {_property.additionalInfo.filter((i) =>
                          ["38"].includes(i.key)
                        )?.length > 0 &&
                          _property.additionalInfo.filter((i) =>
                            ["38"].includes(i.key)
                          )?.[0]?.valueText !== "0#0#0#0#0#0#0#0#0#0#0#0" && (
                            <div className='col-12 additional-info'>
                              <h4>
                                {
                                  _property.additionalInfo.filter((i) =>
                                    ["38"].includes(i.key)
                                  )?.[0]?.name
                                }
                              </h4>
                              <div className='property-info'>
                                <div className='row p-0 m-0'>
                                  <div className='col-12 box'>
                                    <div className='icon blue'>
                                      <NavigationIcon />
                                    </div>
                                    <div className='info'>
                                      <span>
                                        <b>{t("NorthernBorder")}: </b>
                                        {_property.additionalInfo
                                          .filter((i) =>
                                            ["38"].includes(i.key)
                                          )?.[0]
                                          ?.valueText?.split("#")?.[0] === "0"
                                          ? ""
                                          : _property.additionalInfo
                                            .filter((i) =>
                                              ["38"].includes(i.key)
                                            )?.[0]
                                            ?.valueText?.split("#")?.[0] +
                                          " "}
                                        {_property.additionalInfo
                                          .filter((i) =>
                                            ["38"].includes(i.key)
                                          )?.[0]
                                          ?.valueText?.split("#")?.[1] ===
                                          "North"
                                          ? ""
                                          : _property.additionalInfo
                                            .filter((i) =>
                                              ["38"].includes(i.key)
                                            )?.[0]
                                            ?.valueText?.split("#")?.[1] +
                                          " "}
                                        <b>{t("WithLength")} </b>
                                        {renderMeterIfNotExist(
                                          _property.additionalInfo
                                            .filter((i) =>
                                              ["38"].includes(i.key)
                                            )?.[0]
                                            ?.valueText?.split("#")?.[2]
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                  <div className='col-12 box'>
                                    <div className='icon blue'>
                                      <NavigationIcon
                                        sx={{ transform: "rotate(90deg)" }}
                                      />
                                    </div>
                                    <div className='info'>
                                      <span>
                                        <b>{t("EasternBorder")}: </b>
                                        {_property.additionalInfo
                                          .filter((i) =>
                                            ["38"].includes(i.key)
                                          )?.[0]
                                          ?.valueText?.split("#")?.[3] === "0"
                                          ? ""
                                          : _property.additionalInfo
                                            .filter((i) =>
                                              ["38"].includes(i.key)
                                            )?.[0]
                                            ?.valueText?.split("#")?.[3] +
                                          " "}
                                        {_property.additionalInfo
                                          .filter((i) =>
                                            ["38"].includes(i.key)
                                          )?.[0]
                                          ?.valueText?.split("#")?.[4] ===
                                          "East"
                                          ? ""
                                          : _property.additionalInfo
                                            .filter((i) =>
                                              ["38"].includes(i.key)
                                            )?.[0]
                                            ?.valueText?.split("#")?.[4] +
                                          " "}
                                        <b>{t("WithLength")} </b>
                                        {renderMeterIfNotExist(
                                          _property.additionalInfo
                                            .filter((i) =>
                                              ["38"].includes(i.key)
                                            )?.[0]
                                            ?.valueText?.split("#")?.[5]
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                  <div className='col-12 box'>
                                    <div className='icon blue'>
                                      <NavigationIcon
                                        sx={{ transform: "rotate(180deg)" }}
                                      />
                                    </div>
                                    <div className='info'>
                                      <span>
                                        <b>{t("SouthernBorder")}: </b>
                                        {_property.additionalInfo
                                          .filter((i) =>
                                            ["38"].includes(i.key)
                                          )?.[0]
                                          ?.valueText?.split("#")?.[9] === "0"
                                          ? ""
                                          : _property.additionalInfo
                                            .filter((i) =>
                                              ["38"].includes(i.key)
                                            )?.[0]
                                            ?.valueText?.split("#")?.[9] +
                                          " "}
                                        {_property.additionalInfo
                                          .filter((i) =>
                                            ["38"].includes(i.key)
                                          )?.[0]
                                          ?.valueText?.split("#")?.[10] ===
                                          "South"
                                          ? ""
                                          : _property.additionalInfo
                                            .filter((i) =>
                                              ["38"].includes(i.key)
                                            )?.[0]
                                            ?.valueText?.split("#")?.[10] +
                                          " "}
                                        <b>{t("WithLength")} </b>
                                        {renderMeterIfNotExist(
                                          _property.additionalInfo
                                            .filter((i) =>
                                              ["38"].includes(i.key)
                                            )?.[0]
                                            ?.valueText?.split("#")?.[11]
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                  <div className='col-12 box'>
                                    <div className='icon blue'>
                                      <NavigationIcon
                                        sx={{ transform: "rotate(-90deg)" }}
                                      />
                                    </div>
                                    <div className='info'>
                                      <span>
                                        <b>{t("WesternBorder")}: </b>
                                        {_property.additionalInfo
                                          .filter((i) =>
                                            ["38"].includes(i.key)
                                          )?.[0]
                                          ?.valueText?.split("#")?.[6] === "0"
                                          ? ""
                                          : _property.additionalInfo
                                            .filter((i) =>
                                              ["38"].includes(i.key)
                                            )?.[0]
                                            ?.valueText?.split("#")?.[6] +
                                          " "}
                                        {_property.additionalInfo
                                          .filter((i) =>
                                            ["38"].includes(i.key)
                                          )?.[0]
                                          ?.valueText?.split("#")?.[7] ===
                                          "West"
                                          ? ""
                                          : _property.additionalInfo
                                            .filter((i) =>
                                              ["38"].includes(i.key)
                                            )?.[0]
                                            ?.valueText?.split("#")?.[7] +
                                          " "}
                                        <b>{t("WithLength")} </b>
                                        {renderMeterIfNotExist(
                                          _property.additionalInfo
                                            .filter((i) =>
                                              ["38"].includes(i.key)
                                            )?.[0]
                                            ?.valueText?.split("#")?.[8]
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                        {_property.additionalInfo.filter((i) =>
                          ["56"].includes(i.key)
                        )?.length > 0 &&
                          _property.additionalInfo.filter((i) =>
                            ["56"].includes(i.key)
                          )?.[0]?.valueText !== "لا" && (
                            <div className='col-12 additional-info mb-4'>
                              <h4>
                                {
                                  _property.additionalInfo.filter((i) =>
                                    ["56"].includes(i.key)
                                  )?.[0]?.name
                                }
                              </h4>
                              <div className='property-info'>
                                <div className='row p-0 m-0'>
                                  {_property.additionalInfo
                                    .filter((i) => ["56"].includes(i.key))?.[0]
                                    ?.valueText?.split("-")
                                    ?.map((i, index) =>
                                      resolveRerBorders(i, index)
                                    )}
                                </div>
                              </div>
                            </div>
                          )}

                        {_property.amenities.length > 0 && (
                          <div className='col-12 property-amenities'>
                            <h4>{t("Features")}</h4>
                            <div className='row p-0'>
                              {_property.amenities.map((amItem) => (
                                <div
                                  className='col-sm-6 col-lg-4 property-amenity'
                                  key={amItem.key}>
                                  <span className='icon'>
                                    <CircularCheck />
                                  </span>{" "}
                                  {amItem.name}
                                </div>
                              ))}
                            </div>
                          </div>
                        )}

                        {_property.additionalInfo.filter(
                          (i) =>
                            ["5", "6", "7", "50", "51", "52"].includes(i.key) &&
                            i.valueBoolean === "1"
                        )?.length > 0 && (
                            <div className='col-12 additional-info-v2'>
                              <h4>{t("RealEstateServices")}</h4>
                              <div className='row p-0 m-0'>
                                {_property.additionalInfo
                                  .filter(
                                    (i) =>
                                      [
                                        "5",
                                        "6",
                                        "7",
                                        "50",
                                        "51",
                                        "52",
                                        "15",
                                      ].includes(i.key) && i.valueBoolean === "1"
                                  )
                                  .map((AIItem) => (
                                    <div
                                      className={`col-12 col-sm-4 col-lg-4 col-xl-4 mb-4 info`}
                                      key={AIItem.key}>
                                      <span>
                                        {AIItem.valueBoolean === "1" ? (
                                          <GreenCheck green />
                                        ) : (
                                          <RedClose />
                                        )}
                                      </span>
                                      <span>{AIItem.name}</span>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          )}

                        {_property.priceRent.length > 0 &&
                          propertyLicenseNumber &&
                          propertyLicenseNumber !== "لا" && (
                            <div className='col-12 mt-0'>
                              <h4>{t("RentalOptions")}</h4>
                              <div className='row property-rent-options p-0 m-0'>
                                {_property.priceRent.map((item, i) => (
                                  <div key={i} className='col-12'>
                                    <p>
                                      {" "}
                                      <span>
                                        {Intl.NumberFormat("en").format(
                                          item.value
                                        )}{" "}
                                        {t("SAR")}
                                      </span>
                                      &nbsp;<span>{item.name}</span>{" "}
                                    </p>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}

                        {FloorPlans.length > 0 && (
                          <div className='col-12'>
                            <h4>{t("Plans")}</h4>
                            <div className='attachments-wrapper'></div>
                            <ImageList cols={3} gap={15}>
                              {FloorPlans.map((item, i) => (
                                <ImageListItem
                                  key={i}
                                  style={{
                                    border: "1px solid rgba(0, 0, 0, 0.23)",
                                    cursor: "pointer",
                                    borderRadius: 8,
                                    height: "160px",
                                  }}
                                  onClick={() => {
                                    if (item?.includes(".pdf")) {
                                      window.open(item, "_blank");
                                    } else {
                                      setSelectedAttachment(item);
                                      setImageModalOpened(true);
                                    }
                                  }}>
                                  {item?.includes(".pdf") ? (
                                    <img
                                      src={`${publicUrl}assets/img/icons/Icon_pdf_file.png`}
                                      alt={`attachment-${i}`}
                                      loading='lazy'
                                      style={{
                                        objectFit: "contain",
                                        height: "100%",
                                        padding: 10,
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={`${item}`}
                                      srcSet={`${item}`}
                                      alt={`attachment-${i}`}
                                      loading='lazy'
                                      style={{
                                        objectFit: "cover",
                                        borderRadius: 8,
                                        height: "100%",
                                      }}
                                    />
                                  )}
                                </ImageListItem>
                              ))}
                            </ImageList>
                            <ImageModal
                              src={selectedAttachment}
                              open={imageModalOpened}
                              handleClose={() => setImageModalOpened(false)}
                            />
                          </div>
                        )}

                        <div className='col-12 p-0 mt-0 property-location'>
                          <h4>{t("PropertyAddress")}</h4>

                          <p
                            className='d-flex align-items-start mt-2 mb-3'
                            style={{ gap: 8 }}>
                            <FmdBadIcon sx={{ color: "var(--main-color-one)" }} />
                            <span>
                              <b>{t("LocationDescriptionOnMOJDeed")}</b>
                              :&nbsp;
                              {_property.additionalInfo.filter((i) => i.key === "36")?.[0]?.valueText || t("NotAvailable")}
                            </span>
                          </p>
                            
                          <Alert severity="info"
                            style={{
                              maxWidth: 'fit-content',
                              margin: '0 0 20px',
                            }}
                            sx={{
                              "& .MuiAlert-icon": {
                                marginInlineStart: 0,
                                marginInlineEnd: '5px'
                              },
                              '& .MuiAlert-message': {
                                padding: '2px 0',
                                top: '7px',
                                position: "relative"
                              }
                            }}>
                            {t("WeHopeThatTheLocationBelowMatchesTheLocationMentionedInThePropertyAddressDescription")}
                          </Alert>
                          
                          <Dialog
                            fullWidth={true}
                            maxWidth={"xl"}
                            open={openMapModal}
                            className='custom-dialog model full-screen'
                            onClose={() => {
                              setOpenMapModal(false);
                              setExploreNeighbourhood({
                                hospital: false,
                                mosque: false,
                                school: false,
                                shopping_mall: false,
                                // bus_station: false,
                                // train_station: false
                              });
                            }}>
                            <DialogTitle>
                              <div className='dialog-head contact-info p-0'>
                                <h4 className='mb-0'>{t("PlacesSurroundingTheProperty")}</h4>
                                <div className='dialog-actions'>
                                  <IconButton
                                    aria-label='close'
                                    onClick={() => {
                                      setOpenMapModal(false);
                                      setExploreNeighbourhood({
                                        hospital: false,
                                        mosque: false,
                                        school: false,
                                        shopping_mall: false,
                                        // bus_station: false,
                                        // train_station: false
                                      });
                                    }}
                                    sx={{
                                      color: (theme) => theme.palette.grey[500],
                                    }}>
                                    <CloseIcon />
                                  </IconButton>
                                </div>
                              </div>
                            </DialogTitle>
                            <DialogContent>
                              <div className='btns-wrapper map-btns mx-0'>
                                <button
                                  className={`${ExploreNeighbourhood.hospital
                                    ? "primary-btn"
                                    : "outlined-btn"
                                    } px-3 py-2`}
                                  onClick={(e) => {
                                    setExploreNeighbourhood({
                                      // ...ExploreNeighbourhood,
                                      mosque: false,
                                      school: false,
                                      shopping_mall: false,
                                      hospital: !ExploreNeighbourhood.hospital,
                                    });
                                  }}>
                                  {t("Hospitals")}
                                </button>
                                <button
                                  className={`${ExploreNeighbourhood.mosque
                                    ? "primary-btn"
                                    : "outlined-btn"
                                    } px-3 py-2`}
                                  onClick={(e) => {
                                    setExploreNeighbourhood({
                                      // ...ExploreNeighbourhood,
                                      hospital: false,
                                      school: false,
                                      shopping_mall: false,
                                      mosque: !ExploreNeighbourhood.mosque,
                                    });
                                  }}>
                                  {t("Mosques")}
                                </button>
                                <button
                                  className={`${ExploreNeighbourhood.school
                                    ? "primary-btn"
                                    : "outlined-btn"
                                    } px-3 py-2`}
                                  onClick={(e) => {
                                    setExploreNeighbourhood({
                                      // ...ExploreNeighbourhood,
                                      hospital: false,
                                      mosque: false,
                                      shopping_mall: false,
                                      school: !ExploreNeighbourhood.school,
                                    });
                                  }}>
                                  {t("Schools")}
                                </button>
                                <button
                                  className={`${ExploreNeighbourhood.shopping_mall
                                    ? "primary-btn"
                                    : "outlined-btn"
                                    } px-3 py-2`}
                                  onClick={(e) => {
                                    setExploreNeighbourhood({
                                      // ...ExploreNeighbourhood,
                                      hospital: false,
                                      mosque: false,
                                      school: false,
                                      shopping_mall:
                                        !ExploreNeighbourhood.shopping_mall,
                                    });
                                  }}>
                                  {t("Markets")}
                                </button>
                                {/* <button
                                className={
                                  ExploreNeighbourhood.bus_station
                                    ? "primary-btn px-3 py-2"
                                    : "outlined-btn px-3 py-2"
                                }
                                onClick={(e) => {
                                  setExploreNeighbourhood({
                                    ...ExploreNeighbourhood,
                                    bus_station: !ExploreNeighbourhood.bus_station,
                                  });
                                }}>
                                محطة الباص
                              </button> */}
                                {/* <button
                                className={
                                  ExploreNeighbourhood.train_station
                                    ? "primary-btn px-3 py-2"
                                    : "outlined-btn px-3 py-2"
                                }
                                onClick={(e) => {
                                  setExploreNeighbourhood({
                                    ...ExploreNeighbourhood,
                                    train_station:
                                      !ExploreNeighbourhood.train_station,
                                  });
                                }}>
                                محطة القطار
                              </button> */}
                              </div>
                              <div
                                className='row custom-map'
                                style={{
                                  height: "calc(100% - 90px)",
                                  margin: "0px",
                                  position: "relative",
                                }}>
                                {_property.latitude && (
                                  <GoogleMapsContainer
                                    lat={_property.latitude}
                                    lng={_property.longitude}
                                    ExploreNeighbourhood={ExploreNeighbourhood}
                                    dialog
                                  />
                                )}
                              </div>
                            </DialogContent>
                          </Dialog>
                          <div
                            className='btns-wrapper map-btns d-md-flex d-none'
                            style={{ padding: "0 0 20px 0" }}>
                            <button
                              className={`${ExploreNeighbourhood.hospital
                                ? "primary-btn"
                                : "outlined-btn"
                                } px-3 py-2`}
                              onClick={(e) => {
                                setExploreNeighbourhood({
                                  // ...ExploreNeighbourhood,
                                  mosque: false,
                                  school: false,
                                  shopping_mall: false,
                                  hospital: !ExploreNeighbourhood.hospital,
                                });
                              }}>
                              {t("Hospitals")}
                            </button>
                            <button
                              className={`${ExploreNeighbourhood.mosque
                                ? "primary-btn"
                                : "outlined-btn"
                                } px-3 py-2`}
                              onClick={(e) => {
                                setExploreNeighbourhood({
                                  // ...ExploreNeighbourhood,
                                  hospital: false,
                                  school: false,
                                  shopping_mall: false,
                                  mosque: !ExploreNeighbourhood.mosque,
                                });
                              }}>
                              {t("Mosques")}
                            </button>
                            <button
                              className={`${ExploreNeighbourhood.school
                                ? "primary-btn"
                                : "outlined-btn"
                                } px-3 py-2`}
                              onClick={(e) => {
                                setExploreNeighbourhood({
                                  // ...ExploreNeighbourhood,
                                  hospital: false,
                                  mosque: false,
                                  shopping_mall: false,
                                  school: !ExploreNeighbourhood.school,
                                });
                              }}>
                              {t("Schools")}
                            </button>
                            <button
                              className={`${ExploreNeighbourhood.shopping_mall
                                ? "primary-btn"
                                : "outlined-btn"
                                } px-3 py-2`}
                              onClick={(e) => {
                                setExploreNeighbourhood({
                                  // ...ExploreNeighbourhood,
                                  hospital: false,
                                  mosque: false,
                                  school: false,
                                  shopping_mall:
                                    !ExploreNeighbourhood.shopping_mall,
                                });
                              }}>
                              {t("Markets")}
                            </button>
                            {/* <button
                            className={
                              ExploreNeighbourhood.bus_station
                                ? "primary-btn px-3 py-2"
                                : "outlined-btn px-3 py-2"
                            }
                            onClick={(e) => {
                              setExploreNeighbourhood({
                                ...ExploreNeighbourhood,
                                bus_station: !ExploreNeighbourhood.bus_station,
                              });
                            }}>
                            محطة الباص
                          </button> */}
                            {/* <button
                            className={
                              ExploreNeighbourhood.train_station
                                ? "primary-btn px-3 py-2"
                                : "outlined-btn px-3 py-2"
                            }
                            onClick={(e) => {
                              setExploreNeighbourhood({
                                ...ExploreNeighbourhood,
                                train_station:
                                  !ExploreNeighbourhood.train_station,
                              });
                            }}>
                            محطة القطار
                          </button> */}
                          </div>
                          <div className='row'>
                            <div
                              className='col-12'
                              style={{
                                height: "400px",
                                margin: "0px",
                                position: "relative",
                              }}>
                              <div className='map-container with-radius'>
                                {_property.latitude && (
                                  <GoogleMapsContainer
                                    lat={_property.latitude}
                                    lng={_property.longitude}
                                    ExploreNeighbourhood={ExploreNeighbourhood}
                                  />
                                )}
                                <button
                                  className='primary-btn px-3 py-2 d-flex d-md-none'
                                  onClick={() => setOpenMapModal(true)}>
                                  {t("ExploreTheNeighborhood")}
                                </button>
                              </div>
                            </div>
                            <div className='col-12 row mx-0 mt-4 property-info'>
                              {_property.fullAddress
                                .split(",")
                                .map((item, index) => (
                                  <div
                                    className='col-6 col-lg-4 box'
                                    key={index}>
                                    <div className='icon'>
                                      {index === 0 ? (
                                        <MapIcon />
                                      ) : index === 1 ? (
                                        <LocationCityIcon />
                                      ) : index === 2 ? (
                                        <EmojiTransportationIcon />
                                      ) : index === 3 ? (
                                        <Marker
                                          sx={{
                                            color: "var(--main-color-one)",
                                          }}
                                        />
                                      ) : index === 4 ? (
                                        <ApprovalIcon />
                                      ) : index === 6 ? (
                                        <Filter3Icon />
                                      ) : (
                                        <LocalConvenienceStoreIcon />
                                      )}
                                    </div>
                                    <div className='info'>
                                      <h5>{renderLocationTitle(index)}</h5>
                                      <span>
                                        {item?.trim() || t("NotAvailable")}
                                      </span>
                                    </div>
                                  </div>
                                ))}
                              {_property.additionalInfo
                                .filter((i) => ["12", "33"].includes(i.key))
                                .map((AIItem) => (
                                  <div
                                    className='col-6 col-lg-4 box'
                                    key={AIItem.key}>
                                    <div className='icon'>
                                      <SignpostIcon />
                                    </div>
                                    <div className='info'>
                                      <h5>{AIItem.name}</h5>
                                      <span>
                                        {AIItem.valueText === "0"
                                          ? t("No")
                                          : AIItem.valueText === "1"
                                            ? t("Yes")
                                            : AIItem.valueText}
                                      </span>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>

                        <div className='col-12 additional-info'>
                          <h4>{t("ObligationsOnTheProperty")}</h4>
                          <div className='row m-0'>
                            {_property.additionalInfo
                              .filter((i) =>
                                [
                                  "1",
                                  "11",
                                  "13",
                                  "14",
                                  "19",
                                  "2",
                                  "20",
                                  "3",
                                  "30",
                                  "31",
                                  "32",
                                  "35",
                                  "37",
                                  "54",
                                  "55",
                                  "16",
                                  "17",
                                  "53",
                                ].includes(i.key)
                              )
                              .map((AIItem) => (
                                <div
                                  className='col-12 col-sm-6 mb-4 info p-0'
                                  key={AIItem.key}>
                                  <div>
                                    <ListArrow
                                      fill={
                                        DARK_TEMPLATES.includes(+templateId)
                                          ? "var(--heading-color)"
                                          : "black"
                                      }
                                      style={i18n.language === "en" ? { transform: 'rotate(180deg)' } : {}}
                                    />
                                  </div>
                                  <div
                                    className='question'
                                    style={{ maxWidth: "75%" }}>
                                    <h5>{AIItem.name}</h5>
                                    <p style={{ whiteSpace: "pre-line" }}>
                                      {AIItem.valueText === "0"
                                        ? t("No")
                                        : AIItem.valueText === "1"
                                          ? t("Yes")
                                          : AIItem.valueText}
                                    </p>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>

                        {_property.hostInfo.id !== UserId && (
                          <div className='col-12 p-0 mb-3' id='report'>
                            <h4>{t("ReportProblem")}</h4>
                            <p>{t("YouCanReportFakeRealEstateADInappropriateImageOrIncorrectLocation")}</p>
                            {vendor === "amakkn" ? (
                              token ? (
                                <button
                                  className='btn btn-danger rounded-btn mt-3'
                                  onClick={() => setShowReportModal(true)}>
                                  <Report />
                                  {t("ReportNow")}
                                </button>
                              ) : (
                                <LoginPopup
                                  setToken={setToken}
                                  setUserId={setUserId}
                                  reportBtn
                                  btnTyp='3'
                                  text={t("ReportNow")}
                                />
                              )
                            ) : (
                              <button
                                className='btn btn-danger rounded-btn mt-3'
                                onClick={() =>
                                  window.open(
                                    `https://www.amakkn${window.location.origin.includes(
                                      "amakkncompany.com"
                                    ) ||
                                      process.env.REACT_APP_API_URL?.includes(
                                        "devsa2"
                                      )
                                      ? ".org"
                                      : ".com"
                                    }/property-details/${_property.idToShare
                                    }#report`
                                  )
                                }>
                                <Report />
                                {t("ReportNow")}
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`d-none d-lg-block col-12 col-lg-4 widget widget-owner-info owner-sidebar ${isMap ? "no-sticky" : ""
                      }`}
                    id='send-message'>
                    {(propertyLicenseNumber &&
                      propertyLicenseNumber !== "لا") ||
                      ((!propertyLicenseNumber ||
                        propertyLicenseNumber === "لا") &&
                        _property.hostInfo.id === UserId) ? (
                      <>
                        {token ? (
                          <div className='owner-info'>
                            <Link
                              to={{
                                pathname: `${isAdmin ? "/admin" : ""}${_property.hostInfo.accountType === "1"
                                  ? "/user-profile/"
                                  : "/company-profile/"
                                  }${_property.hostInfo.userType === "5"
                                    ? _property.hostInfo.companyIdToShare
                                    : _property.hostInfo.idToShare
                                  }`,
                                aboutProps: { userId: _property.hostInfo.id },
                              }}>
                              <div className='thumb'>
                                {_property.hostInfo.userType === "5" ? (
                                  <Badge
                                    overlap='circular'
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}
                                    badgeContent={
                                      <Link
                                        to={`${isAdmin ? "/admin" : ""
                                          }/company-profile/${_property.hostInfo.idToShare
                                          }`}>
                                        <SmallAvatar
                                          alt='companyAvatar'
                                          src={_property.hostInfo.avatar}
                                        />
                                      </Link>
                                    }>
                                    <Avatar
                                      sx={{
                                        width: 75,
                                        height: 75,
                                        bgcolor: _property.hostInfo
                                          .companyAvatar
                                          ? "#fff"
                                          : "var(--main-color-one)",
                                      }}
                                      alt='image1'
                                      src={_property.hostInfo.companyAvatar}
                                    />
                                  </Badge>
                                ) : (
                                  <Avatar
                                    sx={{
                                      width: 75,
                                      height: 75,
                                      bgcolor: _property.hostInfo.avatar
                                        ? "#fff"
                                        : "var(--main-color-one)",
                                    }}
                                    src={_property.hostInfo.avatar}
                                    alt='image1'
                                  />
                                )}
                              </div>
                            </Link>
                            <div className='details'>
                              <Link
                                to={{
                                  pathname: `${isAdmin ? "/admin" : ""}${_property.hostInfo.accountType === "1"
                                    ? "/user-profile/"
                                    : "/company-profile/"
                                    }${_property.hostInfo.userType === "5"
                                      ? _property.hostInfo.companyIdToShare
                                      : _property.hostInfo.idToShare
                                    }`,
                                  aboutProps: { userId: _property.hostInfo.id },
                                }}>
                                <h5>
                                  {_property.hostInfo.userType === "5"
                                    ? _property.hostInfo.companyName
                                    : _property.hostInfo.name}
                                </h5>
                              </Link>
                              <span className='designation'>
                                {_property.hostInfo.userType === "5" ? (
                                  <Link
                                    to={`${isAdmin ? "/admin" : ""
                                      }/company-profile/${_property.hostInfo.idToShare
                                      }`}>
                                    {`${t("Agent")}: ` + _property.hostInfo.name}
                                  </Link>
                                ) : (
                                  resolveUserTypeName(
                                    _property.hostInfo.userType
                                  )
                                )}
                                {_property.hostInfo.isUserVerified >= 2 && (
                                  <img
                                    src={
                                      publicUrl + "assets/img/icons/verfiy.svg"
                                    }
                                    alt='image2'
                                    style={{ margin: "0 2px" }}
                                  />
                                )}
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div
                            className='owner-info'
                            style={{ cursor: "pointer" }}>
                            <LoginPopup
                              setToken={setToken}
                              setUserId={setUserId}
                              path={`${isAdmin ? "/admin" : ""}${_property.hostInfo.accountType === "1"
                                ? "/user-profile/"
                                : "/company-profile/"
                                }${_property.hostInfo.userType === "5"
                                  ? _property.hostInfo.companyIdToShare
                                  : _property.hostInfo.idToShare
                                }`}
                              state={{ userId: _property.hostInfo.id }}>
                              <div className='thumb'>
                                {_property.hostInfo.userType === "5" ? (
                                  <Badge
                                    overlap='circular'
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}
                                    badgeContent={
                                      <Link
                                        to={`${isAdmin ? "/admin" : ""
                                          }/company-profile/${_property.hostInfo.idToShare
                                          }`}>
                                        <SmallAvatar
                                          alt='companyAvatar'
                                          src={_property.hostInfo.avatar}
                                        />
                                      </Link>
                                    }>
                                    <Avatar
                                      sx={{
                                        width: 75,
                                        height: 75,
                                        bgcolor: _property.hostInfo
                                          .companyAvatar
                                          ? "#fff"
                                          : "var(--main-color-one)",
                                      }}
                                      alt='image1'
                                      src={_property.hostInfo.companyAvatar}
                                    />
                                  </Badge>
                                ) : (
                                  <Avatar
                                    sx={{
                                      width: 75,
                                      height: 75,
                                      bgcolor: _property.hostInfo.avatar
                                        ? "#fff"
                                        : "var(--main-color-one)",
                                    }}
                                    src={_property.hostInfo.avatar}
                                    alt='image1'
                                  />
                                )}
                              </div>
                            </LoginPopup>
                            <div className='details'>
                              <LoginPopup
                                setToken={setToken}
                                setUserId={setUserId}
                                path={`${isAdmin ? "/admin" : ""}${_property.hostInfo.accountType === "1"
                                  ? "/user-profile/"
                                  : "/company-profile/"
                                  }${_property.hostInfo.userType === "5"
                                    ? _property.hostInfo.companyIdToShare
                                    : _property.hostInfo.idToShare
                                  }`}
                                state={{ userId: _property.hostInfo.id }}>
                                <h5>
                                  {_property.hostInfo.userType === "5"
                                    ? _property.hostInfo.companyName
                                    : _property.hostInfo.name}
                                </h5>
                              </LoginPopup>
                              <span className='designation'>
                                {_property.hostInfo.userType === "5" ? (
                                  <Link
                                    to={`${isAdmin ? "/admin" : ""
                                      }/company-profile/${_property.hostInfo.idToShare
                                      }`}>
                                    {`${t("Agent")}: ` + _property.hostInfo.name}
                                  </Link>
                                ) : (
                                  resolveUserTypeName(
                                    _property.hostInfo.userType
                                  )
                                )}
                                {_property.hostInfo.isUserVerified >= 2 && (
                                  <img
                                    src={
                                      publicUrl + "assets/img/icons/verfiy.svg"
                                    }
                                    alt='image2'
                                    style={{ margin: "0 2px" }}
                                  />
                                )}
                              </span>
                            </div>
                          </div>
                        )}
                        {_property.hostInfo.id !== UserId ? (
                          <div>
                            <div className='contact'>
                              {vendor === "amakkn" ? (
                                token ? UserId === "1" ?
                                  _property.status !== "2" ? (
                                    <Link
                                      className='btn primary-btn btn-blue px-3 d-flex justify-content-center'
                                      to={`${isAdmin ||
                                        window.location.href.includes("properties")
                                        ? "/admin/property"
                                        : ""
                                        }/edit-property/${_property.propertyId}`}>
                                      {t("EditAD")}
                                    </Link>
                                  ) : <button
                                    className='btn primary-btn d-flex justify-content-center w-100'
                                    onClick={(e) => handleSendMessage()}>
                                    {t("SendMessageToAdvertiser")}
                                  </button>
                                  : (
                                    <button
                                      className='btn primary-btn d-flex justify-content-center w-100'
                                      onClick={(e) => handleSendMessage()}>
                                      {t("SendMessageToAdvertiser")}
                                    </button>
                                  ) : (
                                  <LoginPopup
                                    setToken={setToken}
                                    setUserId={setUserId}
                                    ToChat={true}
                                    btnTyp='1'
                                    fullWidth
                                    userId2={_property.hostInfo.id}
                                    propertyId={_property.propertyId}
                                    settext={t("SendMessageToAdvertiser")}
                                  />
                                )
                              ) : (
                                <button
                                  className='btn primary-btn d-flex justify-content-center w-100'
                                  onClick={() =>
                                    window.open(
                                      `https://www.amakkn${window.location.origin.includes(
                                        "amakkncompany.com"
                                      ) ||
                                        process.env.REACT_APP_API_URL?.includes(
                                          "devsa2"
                                        )
                                        ? ".org"
                                        : ".com"
                                      }/property-details/${_property.idToShare
                                      }#send-message`
                                    )
                                  }>
                                  {t("SendMessageToAdvertiser")}
                                </button>
                              )}
                            </div>
                            <div className='contact-info'>
                              <h6 className='mb-2'>{t("AdvertiserInfo")}</h6>

                              {renderLicense(_property)}

                              {/* {_property.hostInfo.companyId && _property.hostInfo.userType === '5' && (
                                <div className='media'>
                                  <div className='media-left'>
                                    <BusinessIcon sx={{ color: 'var(--main-color-one)' }} />
                                  </div>
                                  <div className='media-body'>
                                    <p>المنشأة العقاريّة التابع لها</p>
                                    <Link to={(isAdmin ? '/admin' : '') + '/company-profile/' + _property.hostInfo.companyIdToShare}>
                                      {_property.hostInfo.companyName}
                                    </Link>
                                  </div>
                                </div>
                              )} */}

                              {/* {_property.hostInfo.address && (
                              <div className='media'>
                                <div className='media-left'>
                                  <Marker />
                                </div>
                                <div className='media-body'>
                                  <p>{t("Address")}</p>
                                  <span>{_property.hostInfo.address}</span>
                                </div>
                              </div>
                            )} */}
                              {propertyLicenseNumber &&
                                propertyLicenseNumber !== "لا" && (
                                  <div className='media'>
                                    <div className='media-left'>
                                      <Tel />
                                    </div>
                                    <div className='media-body'>
                                      <p>{t("PhoneNumber")}</p>
                                      <a
                                          style={{ textDecoration: 'underline' }}
                                          target={isLargeScreen ? '_blank' : "_self"}
                                          rel="noreferrer"
                                        href={
                                          "tel:" +
                                          _property.hostInfo.countryCode +
                                          _property.hostInfo.phone
                                        }>
                                        0{_property.hostInfo.phone}
                                      </a>
                                      {_property?.additionalInfo?.filter(
                                        (i) => i.key === "23"
                                      )?.[0]?.valueText &&
                                        _property?.additionalInfo?.filter(
                                          (i) => i.key === "23"
                                        )?.[0]?.valueText !== "لا" &&
                                        _property?.additionalInfo
                                          ?.filter((i) => i.key === "23")?.[0]
                                          ?.valueText?.indexOf(
                                            _property?.hostInfo?.phone?.substring(
                                              1
                                            )
                                          ) === -1 ? (
                                        <>
                                          &nbsp; - &nbsp;
                                          <a
                                            style={{
                                              display: "inline-block",
                                              direction: "ltr",
                                              textDecoration: 'underline'
                                                }}
                                                rel="noreferrer"
                                                target={isLargeScreen ? '_blank' : "_self"}
                                            href={`tel:${_property?.additionalInfo?.filter(
                                              (i) => i.key === "23"
                                            )?.[0]?.valueText
                                              }`}>
                                            {
                                              _property?.additionalInfo?.filter(
                                                (i) => i.key === "23"
                                              )?.[0]?.valueText
                                            }
                                          </a>
                                        </>
                                      ) : null}
                                    </div>
                                  </div>
                                )}

                              {_property.hostInfo.unifiedNumber && (
                                <div className='media'>
                                  <div className='media-left'>
                                    <Tel />
                                  </div>
                                  <div className='media-body'>
                                    <p>{t("UnifiedContactNumber")}</p>
                                    <span>
                                      {_property.hostInfo.unifiedNumber}
                                    </span>
                                  </div>
                                </div>
                              )}
                              {_property.hostInfo.email && (
                                <div className='media'>
                                  <div className='media-left'>
                                    <Mail />
                                  </div>
                                  <div className='media-body'>
                                    <p>{t("Email")}</p>
                                    <a
                                      style={{ textDecoration: 'underline' }}
                                      href={
                                        "mailto:" + _property.hostInfo.email
                                      }>
                                      {_property.hostInfo.email}
                                    </a>
                                  </div>
                                </div>
                              )}

                              {_property.hostInfo.website && vendor === "amakkn" && (
                                <div className='media'>
                                  <div className='media-left'>
                                    <Mail />
                                  </div>
                                  <div className='media-body'>
                                    <p>{t("Website")}</p>
                                    <a
                                      target='_blank'
                                      rel='noreferrer'
                                      style={{ textDecoration: 'underline' }}
                                      href={
                                        "https://" + _property.hostInfo.website
                                      }>
                                      {_property.hostInfo.website}
                                    </a>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className='text-align-last-center'>
                            {_property.status !== "2" && (
                              <Link
                                className='btn primary-btn btn-blue px-3 d-flex justify-content-center'
                                to={`${isAdmin ||
                                  window.location.href.includes("properties")
                                  ? "/admin/property"
                                  : ""
                                  }/edit-property/${_property.propertyId}`}>
                                {t("EditAD")}
                              </Link>
                            )}
                          </div>
                        )}
                        {(!propertyLicenseNumber ||
                          propertyLicenseNumber === "لا") && (
                            <>
                              <Alert
                                severity={"warning"}
                                className='info-alert mt-3'
                                sx={{
                                  "& .MuiAlert-message": { width: "100%" },
                                  "& .MuiPaper-root": {
                                    minWidth: "100%",
                                    background: "#FEF2E3",
                                  },
                                }}>
                                <AlertTitle
                                  sx={{
                                    width: "100%",
                                    textAlign: "right",
                                    display: "flex",
                                    flexWrap: "wrap",
                                    justifyContent: "space-between",
                                  }}>
                                  {t("WaitingForTheADLicenseNumber")}
                                </AlertTitle>
                                {t("AccordingToTheNewRulesAndRegulations")}
                                <a
                                  rel='noreferrer'
                                  style={{
                                    textDecoration: "underline",
                                    fontWeight: 500,
                                  }}
                                  href='https://eservices.rega.gov.sa/'
                                  target='_blank'>
                                  {" "}
                                  {t("ElectronicServicesOfTheGeneralAuthorityForRealEstate")}
                                </a>
                              </Alert>
                            </>
                          )}
                      </>
                    ) : (
                      <Alert
                        severity={"warning"}
                        className='info-alert'
                        sx={{
                          "& .MuiAlert-message": { width: "100%" },
                          "& .MuiPaper-root": {
                            minWidth: "100%",
                            background: "#FEF2E3",
                          },
                        }}>
                        <AlertTitle
                          sx={{
                            width: "100%",
                            textAlign: "right",
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "space-between",
                          }}>
                          {t("WaitingForTheADLicenseNumber")}
                        </AlertTitle>
                        {t("AccordingToTheNewRulesAndRegulations")}
                        <a
                          rel='noreferrer'
                          style={{
                            textDecoration: "underline",
                            fontWeight: 500,
                          }}
                          href='https://eservices.rega.gov.sa/'
                          target='_blank'>
                          {" "}
                          {t("ElectronicServicesOfTheGeneralAuthorityForRealEstate")}
                        </a>
                      </Alert>
                    )}

                    <Dialog
                      fullWidth={true}
                      maxWidth={"sm"}
                      open={open}
                      className='custom-dialog'
                      sx={{
                        "& .MuiPaper-root": {
                          margin: 0,
                          width: "calc(100% - 20px)",
                        },
                      }}
                      onClose={handleClose}>
                      <DialogTitle>
                        <div className='dialog-head'>
                          <div>
                            <h2>{t("SubmitComplaint")}</h2>
                          </div>
                          <div className='dialog-actions close-only'>
                            <span
                              onClick={handleClose}
                              style={{ cursor: "pointer" }}>
                              <Close />
                            </span>
                          </div>
                        </div>
                      </DialogTitle>
                      <DialogContent>
                        <div className='row justify-content-start'>
                          <div style={{ display: "flex", width: "100%" }}>
                            <div
                              style={{
                                flexGrow: 1,
                                width: "100%",
                                padding: "0 10px",
                                textAlign: "right",
                              }}>
                              <p style={{ padding: "0 28px" }}>{t("PleaseSelectOneOrMoreReasons")}</p>
                              <List
                                sx={{
                                  width: "100%",
                                  maxWidth: 360,
                                  bgcolor: "transparent",
                                  padding: 0,
                                  margin: "0 0 20px 0",
                                  textAlignLast: "right",
                                }}>
                                {REGASubjects.map((value) => {
                                  const labelId = `checkbox-list-label-${value.key}`;

                                  return (
                                    <ListItem
                                      key={value.key}
                                      disablePadding>
                                      <ListItemButton
                                        role={undefined}
                                        onClick={handleToggle(value.key)}
                                        dense>
                                        <ListItemIcon>
                                          <Checkbox
                                            edge='start'
                                            checked={
                                              checked.indexOf(value.key) !== -1
                                            }
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{
                                              "aria-labelledby": labelId,
                                            }}
                                          />
                                        </ListItemIcon>
                                        <ListItemText
                                          id={labelId}
                                          primary={value.name}
                                        />
                                      </ListItemButton>
                                    </ListItem>
                                  );
                                })}
                              </List>
                            </div>
                          </div>
                        </div>
                        <div className='d-flex justify-content-end'>
                          <Button
                            className='primary-btn py-2 px-4'
                            autoFocus
                            onClick={handleSendREGAIssue}>
                            {t("SendComplaint")}
                          </Button>
                        </div>
                      </DialogContent>
                    </Dialog>

                    {AllowedUsers.includes(UserId) &&
                      REGASubjects.length > 0 && (
                        <div className='text-align-last-center'>
                          <Button
                            className='btn primary-btn danger-btn mt-3 px-3 d-flex justify-content-center w-100'
                            onClick={handleClickOpen}>
                            {_property.REGAButtonTitle}
                          </Button>
                        </div>
                      )}
                  </div>
                  <Dialog
                    onClose={() => setShowReportModal(false)}
                    maxWidth='xs'
                    className='custom-dialog'
                    sx={{
                      "& .MuiPaper-root": {
                        margin: 0,
                        width: "calc(100% - 20px)",
                        padding: 0,
                      },
                    }}
                    open={showReportModal}>
                    <DialogContent sx={{ padding: 0, overflow: "hidden" }}>
                      <div className='modal2'>
                        <button
                          className='close'
                          onClick={() => setShowReportModal(false)}>
                          &times;
                        </button>
                        <div
                          className='login-modal'
                          style={{ minWidth: "100%" }}>
                          <div className='col-10 col-sm-9'>
                            <h4 style={{ fontSize: "28px" }}>{t("AddNewComplaintSuggestion")}</h4>
                            <FormControl required className='mb-3 mt-4 col-12'>
                              <InputLabel id='type-label'>
                                {t("Subject")}
                              </InputLabel>
                              <Select
                                label={t("Subject")}
                                labelId='type-label'
                                name='name'
                                sx={{ width: "100%" }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                input={
                                  <OutlinedInput
                                    notched
                                    required
                                    label={t("Subject")}
                                    id='type'
                                  />
                                }
                                size='small'
                                variant='outlined'
                                value={NewTicket.subjectId}
                                onChange={(e) => handleSubjectChanges(e)}>
                                {options.map((opt) => (
                                  <MenuItem value={opt.value} key={opt.value}>
                                    {opt.label}
                                  </MenuItem>
                                ))}
                              </Select>
                              <FormHelperText>
                                {errors && NewTicket.subjectId === "0" ? (
                                  <Alert
                                    severity='error'
                                    className='custom-alert'
                                    style={{ width: "fit-content" }}>
                                    {t("ThisFieldIsMandatory")}
                                  </Alert>
                                ) : (
                                  ""
                                )}
                              </FormHelperText>
                            </FormControl>

                            <FormControl className='mb-3 col-12'>
                              <TextField
                                label={t("Message")}
                                required
                                sx={{ width: "100%" }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder={t("PleaseEnterTheComplaintSuggestionTextInDetail")}
                                value={NewTicket.notes}
                                size='small'
                                multiline
                                rows={6}
                                onChange={(e) =>
                                  setNewTicket({
                                    ...NewTicket,
                                    notes: e.currentTarget.value,
                                  })
                                }
                              />
                              <FormHelperText>
                                {errors && !NewTicket.notes ? (
                                  <Alert
                                    severity='error'
                                    style={{ width: "fit-content" }}
                                    className='custom-alert'>
                                    {t("ThisFieldIsMandatory")}
                                  </Alert>
                                ) : (
                                  ""
                                )}
                              </FormHelperText>
                            </FormControl>
                            <LoadingButton
                              classes='btn primary-btn px-3 mb-3 w-100 d-flex justify-content-center'
                              loading={isSubmittingTicket}
                              label={t("Send")}
                              handleClick={(e) => {
                                if (DEMO_VENDORS.includes(vendor)) {
                                  setOpenDemoSnackBar(true);
                                } else {
                                  onSubmitNewTicket();
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </DialogContent>
                  </Dialog>
                  {DEMO_VENDORS.includes(vendor) &&
                    getDemoSnakbar(openDemoSnackBar, setOpenDemoSnackBar)}
                </div>
                {vendor === "amakkn" ? (
                  <div className='row second-row'>
                    <div className='col-12 p-0'>
                      <RecomandProperties
                        id={propertyId}
                        isMap={isMap}
                        onItemSelected={props.onItemSelected}
                      />
                    </div>
                  </div>
                ) : (
                  <div style={{ marginBottom: "50px" }}></div>
                )}
                <SetPropertyLocationDialog
                  openMapDialog={openMapDialog}
                  onOK={getData}
                  setOpenMapDialog={setOpenMapDialog}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    ) : (
      _msg && (
        <Forbidden btnLink={"/"} btnText={t("BackToHomePage")} desc={_msg} />
      )
    )
  ) : <PropertyLoader isMap={isMap} />;
}

export default PropertyDetails;
