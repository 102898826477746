import React, { useEffect, useState } from "react";
import DomainChooser from "../../../components/domain-chooser";
import { Link } from "react-router-dom";
import SubHeader from "../../../components/global-components/sub-header";
import { useAuth } from "../../../helpers/context";
import LoadingButton from "../../../components/global-components/loading-btn";
import { Alert, AlertTitle } from "@mui/material";
import { checkSaudi, getWLProfile } from "../../../api/userApi";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { CustomerSupportIcon } from "../../../constants/icons";

export default function Domain() {
  const { currentPlan, currentDomain, token, vendor } = useAuth();
  const [isDomainVerified, setIsDomainVerified] = useState();
  const [isSubmittingData, setIsSubmittingData] = useState(false);
  const { t, i18n } = useTranslation();
  
  useEffect(() => {
    if (token) {
      getWLProfile({ userName: vendor, language: i18n.language === 'ar' ? '0' : '1' }).then((_userInfo) => {
        if (_userInfo.resCode === 0) {
          setIsDomainVerified(_userInfo.response.wlUser.saudiBusinessStatus);
        }
      });
    }
  }, [token, vendor, i18n]);

  return (
    <>
      <SubHeader OKElement={<></>} />
      <div className='custom-card without-header'>
        <div className="card-body">
          <div className="d-flex align-items-center justify-content-between">
            <h4>{t("CurrentDomain")}</h4>
            {(isDomainVerified === '-1' || isDomainVerified === '0') &&  !currentDomain.includes('.amakkn') && <LoadingButton
              loading={isSubmittingData}
              label={t("CheckOfDomainVerification")}
              handleClick={() => {
                setIsSubmittingData(true);
                checkSaudi({ userId: token }).then(res => {
                  if (res.resCode === 0) {
                    setIsDomainVerified(res.response);
                    if (res.response === '1') {
                      toast.success(t("YourDomainIsVerifiedByTheSaudiBusinessCenter"));
                    } else {
                      toast.error(t("YourDomainHasNotYetBeenVerifiedByTheSaudiBusinessCenter"));
                    }
                  } else {
                    toast.error(res.resStr);
                  }
                }).finally(() => setIsSubmittingData(false));
              }}
            />}
          </div>
          <p style={{ fontSize: 20 }}>{currentDomain}</p>
          {(isDomainVerified === '-1' || isDomainVerified === '0') &&  !currentDomain.includes('.amakkn') && 
            <Alert color="error">
              <AlertTitle>{t("Alert")}</AlertTitle>
              <p>{t("YourCurrentDomain")} <b>{currentDomain}</b> {t("DomainIsNotVerified1")} <a target="_blank" href="https://business.sa/" rel="noreferrer" style={{ color: 'var(--main-color-one)', textDecoration: 'underline' }}>{t("FromHere")}</a> {t("DomainIsNotVerified2")}</p>
            </Alert>
          }
          {isDomainVerified === '1' &&  !currentDomain.includes('.amakkn') &&
            <Alert color="success" style={{ width: 'fit-content', display: 'flex', alignItems: 'center', gap: 5 }}>
              <p style={{ lineHeight: 1, top: 5, position: 'relative' }}>{t("YourDomainIsVerifiedByTheSaudiBusinessCenter")}</p>
            </Alert>
          }
        </div>
      </div>
      {
        currentDomain.includes('.amakkn') && (
          <>
            <div className='custom-card'>
              <div className="card-header">
                <h4>{t("ChangeDomain")}</h4>
                <h6>{t("YouCanChooseNewDomainBySearchingInTheListBelow")}</h6>
              </div>
              <div className="card-body">
                <DomainChooser from="admin" selectedPlan={currentPlan?.plan} />
              </div>
            </div>

            <div className='custom-card without-header call-us'>
              <div className="card-body">
                <div className="flex-wrapper">
                  <div>
                    <h5>{t("HelpCenter")}</h5>
                    <p>{t("DoNotHesitateToContactUs")}</p>
                  </div>
                  <div>
                    <Link to="/admin/ticket" className="primary-btn justify-content-between" >
                      <CustomerSupportIcon />
                      <span>
                        {t("HelpCenter")}
                      </span>
                    </Link>
                  </div>
            
                </div>
              </div>
            </div>
          </>
        )
      }
    </>
  );
}