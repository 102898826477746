import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import YoutubeDialog from "../../youtube-dialog";
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import LoadingButton from "../../global-components/loading-btn";
import { REGIONS } from "../../../constants";
import { useNavigate } from "react-router-dom";

const T06Banner = ({ data, ImageTOView, youtubeLink }) => {
    const { t, i18n } = useTranslation();
    const [searchObj, setSearchObj] = useState({
        adPurpose: '3',
        region: '1',
        propertyType: 'all'
    });
    const history = useNavigate();
    
    const inlineStyle = {
        background: "url(" + ImageTOView + ") no-repeat center",
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed'
    };
    
    return (
        <>
            <div className={`banner-area2 banner-area banner-04`} >
                <div className='row mx-0 w-100 position-relative'>
                    <div className="col-12 col-lg-6 h-100 w-100 image" style={inlineStyle}></div>
                    <div className='banner-inner-wrap col-12 col-lg-6 position-absolute position-lg-initial ' style={{ placeContent: "start" }}>
                        <div className='row container mx-0'>
                            <div className='col-12'>
                                <div className='banner-inner'>
                                    <div data-aos='zoom-in' className="content" style={{alignItems: 'inherit'}}>
                                        <h3
                                            data-aos='fade-up'
                                            data-aos-easing='ease'
                                            data-aos-delay='600'
                                            style={{ textShadow: "-.15rem .15rem 0 rgba(0, 0, 0, .35)" }}
                                            className="sub-title text-center text-lg-start">
                                            {data.title2}
                                        </h3>
                                        <h1
                                            data-aos='fade-up'
                                            data-aos-easing='ease'
                                            data-aos-delay='800'
                                            style={{ textShadow: "-.15rem .15rem 0 rgba(0, 0, 0, .35)" }}
                                            className="title text-center text-lg-start">
                                            {data.title1}
                                        </h1>
                                        {youtubeLink && (
                                            <YoutubeDialog
                                                position={data.postion}
                                                youtubeLink={youtubeLink}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className="search-box row px-3 py-4 px-lg-5 py-lg-5"
                data-aos='fade-up'
                data-aos-easing='ease'
                data-aos-delay='1200'
            >
              
                <div className='col-12 col-lg-3 mb-3 mb-lg-0'>
                    <FormControl variant="outlined" className="w-100">
                        <InputLabel shrink htmlFor='unitid1'>{t("Region")}</InputLabel>
                        <Select
                            sx={{ width: "100%" }}
                            size="medium"
                            onChange={(event) => setSearchObj({ ...searchObj, region: event.target.value })}
                            label={t("Region")}
                            inputProps={{ name: "unitid1", id: "unitid1" }}
                            value={searchObj.region}
                            input={<OutlinedInput
                                notched
                                value={searchObj.region}
                                label={t("Region")} />}
                            labelId="unitid1"
                        >
                            {REGIONS.map(i => <MenuItem value={i.REGION_ID} key={i.REGION_ID}>{i18n.language === "ar" ? i.REGIONNAME_AR : i.REGIONNAME_EN}</MenuItem>)}
                            <MenuItem value={15} key={15}>{i18n.language === "ar" ? 'جده' : 'Jida'}</MenuItem>
                            <MenuItem value={16} key={16}>{i18n.language === "ar" ? 'الدمام' : 'Al Dammam'}</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className='col-12 col-lg-3 mb-3 mb-lg-0'>
                    <FormControl variant="outlined" className="w-100">
                        <InputLabel shrink htmlFor='unitid1'>{t("ADPurpose")}</InputLabel>
                        <Select
                            sx={{ width: "100%" }}
                            size="medium"
                            onChange={(event) => setSearchObj({ ...searchObj, adPurpose: event.target.value })}
                            label={t("ADPurpose")}
                            inputProps={{ name: "unitid1", id: "unitid1" }}
                            value={searchObj.adPurpose}
                            input={<OutlinedInput
                                notched
                                value={searchObj.adPurpose}
                                label={t("ADPurpose")} />}
                            labelId="unitid1"
                        >
                            <MenuItem value={'3'} key={'3'}>{t("All")}</MenuItem>
                            <MenuItem value={'1'} key={'1'}>{t("ForRent")}</MenuItem>
                            <MenuItem value={'2'} key={'2'}>{t("ForSale")}</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className='col-12 col-lg-3 mb-3 mb-lg-0'>
                    <FormControl variant="outlined" className="w-100">
                        <InputLabel shrink htmlFor='unitid1'>{t("PropertyType")}</InputLabel>
                        <Select
                            sx={{ width: "100%" }}
                            size="medium"
                            onChange={(event) => setSearchObj({ ...searchObj, propertyType: event.target.value })}
                            label={t("PropertyType")}
                            inputProps={{ name: "unitid1", id: "unitid1" }}
                            value={searchObj.propertyType}
                            input={<OutlinedInput
                                notched
                                value={searchObj.propertyType}
                                label={t("PropertyType")} />}
                            labelId="unitid1"
                        >
                            <MenuItem value='all'>{t("All")}</MenuItem>

                            <MenuItem value='1'>{t("Land")}</MenuItem>
                            <MenuItem value='14'>{t("Floor")}</MenuItem>
                            <MenuItem value='3'>{t("Apartment")}</MenuItem>
                            <MenuItem value='4'>{t("Villa")}</MenuItem>
                            <MenuItem value='12'>{t("Studio")}</MenuItem>
                            <MenuItem value='13'>{t("Room")}</MenuItem>
                            <MenuItem value='10'>{t("Resort")}</MenuItem>
                            <MenuItem value='8'>{t("Showroom")}</MenuItem>
                            <MenuItem value='9'>{t("Office")}</MenuItem>
                            <MenuItem value='7'>{t("Warehouse")}</MenuItem>
                            <MenuItem value='11'>{t("Farm")}</MenuItem>
                            <MenuItem value='2'>{t("Building")}</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className="col-12 col-lg-3">
                    <LoadingButton
                        type="submit"
                        style={{ width: 'fit-content', height: '40px' }}
                        classes='btn primary-btn w-100 d-flex align-items-center justify-content-center mx-auto no-border-radius'
                        label={t("Search")}
                        loading={false}
                        handleClick={() => {
                            let lat = '', long = '';
                            if ([15, 16].includes(searchObj.region)) {
                                
                                if (searchObj.region === 15) {
                                    lat = "21.51271667";
                                    long = "39.18266667";
                                } else if (searchObj.region === 16) {
                                    lat = "26.44683333";
                                    long = "50.10598333";
                                }
                            } else {
                                lat = REGIONS.filter(i => +i.REGION_ID === +searchObj.region)?.[0]?.REGION_LAT;
                                long = REGIONS.filter(i => +i.REGION_ID === +searchObj.region)?.[0]?.REGION_LONG;
                            }
                            history(`/properties/${searchObj.adPurpose}/${searchObj.propertyType}/${lat}/${long}/createdAt/11/1`);
                        }}
                    />
                </div>
            </div>
        </>
    );
}

export default T06Banner;
