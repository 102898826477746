import React, { useEffect, useState } from "react";
import { getUserProile, requestNafath } from "../../../api/userApi";
import { Link, useNavigate } from "react-router-dom";
import CompanyValidation from "./components/company-validation";
import LoginPopup from "../../../components/user-components/login-popup";
import { Alert, Box, CircularProgress, FormControl, FormHelperText, TextField } from "@mui/material";
import AppLayout from "../../../components/layouts/app-layout";
import { useAuth } from "../../../helpers/context";
import { useTranslation } from "react-i18next";
import CheckNafazOtp from "./components/check-nafaz-otp";
import LoadingButton from "../../../components/global-components/loading-btn";
import { toast } from "react-toastify";

const publicUrl = process.env.REACT_APP_URL + "/";

export default function Verify2() {
  const { token, setToken, setUserId, setUserType } = useAuth();
  const [isUserVerified, setIsUserVerified] = useState(false);
  const [loadingUserInfo, setLoadingUserInfo] = useState(true);
  const [userInformation, setuserInformation] = useState();
  const [userType, setuserType] = useState("");
  const history = useNavigate();
  const { t, i18n } = useTranslation();
  const [isSubmittingVerification, setIsSubmittingVerification] = useState(false);
  const [isStartChecking, setIsStartChecking] = useState(false);
  const [ID, setID] = useState('');
  const [errors, setErrors] = useState({});
  const [random, setRandom] = useState('');
  const [transId, setTransId] = useState('');
  
  function startVerification() {
    if (!formIsValid())
      return;
    setIsStartChecking(true);
    requestNafath({ userId: token, language: i18n.language === 'ar' ? '0' : '1', nationalId: ID }).then(res => {
      if (res.resCode === 0) {
        setRandom(res.response.random);
        setTransId(res.response.transId);
        setIsSubmittingVerification(true);
      } else {
        toast.error(res.resStr);
      }
    }).finally(() => {
      setIsStartChecking(false);
    });
  }

  useEffect(() => {
    if (token)
      getUserProile({
        userId: token,
        language: i18n.language === 'ar' ? '0' : '1',
      })
        .then((_userInfo) => {
          if (_userInfo.resCode === 0) {
            setLoadingUserInfo(false);
            setuserInformation(_userInfo.response.userProfile);
        
            switch (_userInfo.response.userProfile.isUserVerified) {
              case "0":
                setuserType(_userInfo.response.userProfile.userType);
                break;
              case "1":
                history(
                  "/companylocation/" +
                  (_userInfo.response.userProfile.latitude
                    ? _userInfo.response.userProfile.latitude
                    : "24.74772941029266") +
                  "/" +
                  (_userInfo.response.userProfile.longitude
                    ? _userInfo.response.userProfile.longitude
                    : "46.619290983645406") +
                  "/" +
                  (_userInfo.response.userProfile.address
                    ? _userInfo.response.userProfile.address
                    : "empty")
                );
                break;
              case "2":
                history("/successverfication");
                break;
              case "3":
                setIsUserVerified(true);
                break;
              default:
                break;
            }
          } else {
            setLoadingUserInfo(false);
          }
        })
        .catch((err) => setLoadingUserInfo(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);


  function formIsValid() {
    const _errors = {};
  
    if (!ID) _errors.id = t("ThisFieldIsMandatory");
    else if (ID.length !== 10)
      _errors.id = t("MustBeContains10Numbers");
   
    
    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  return (
    <AppLayout needAuth pageTitle={t("AccountVerification")}>
      <div className='page-wrapper page-width'>
        <div className='row page-wrapper-without-top'>
          <div className='col-12 col-lg-6 details-wrapper scrolled'>
            <div
              style={{
                maxHeight: "100vh",
                overflowY: "auto",
                padding: "20px 10% 70px",
              }}>
              <Box
                sx={{
                  marginBottom: "40px",
                  fontSize: "28px",
                  fontWeight: 600,
                  padding: "10px 0",
                  color: "#000",
                }}>
                {t("AccountVerification")}
              </Box>
              {loadingUserInfo ? (
                <div className='d-flex align-items-center justify-content-center w-100 h-100'>
                  <CircularProgress />
                </div>
              ) : !isSubmittingVerification ? (userInformation ? (
                userType !== "1" ? (
                  !isUserVerified ? (
                    <>
                      {userType === "4" ? (
                        <CompanyValidation />
                      ) : (
                        <>
                          <div className='col-12 p-0'>
                            <h5 className='mb-4'>{t("TheAccountWillBeVerifiedThroughNationalUnifiedAccessApplication")}</h5>
                          </div>
                              
                          <div className='col-12 col-md-6 col-lg-8 mb-4 p-0'>
                            <FormControl
                              error={Boolean(errors.id)}
                              className="col-12"
                            >
                              <TextField
                                id='id'
                                label={t("IDResidence")}
                                sx={{ width: "100%" }}
                                size='small'
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => setID(e.currentTarget.value)}
                                name='id'
                                error={Boolean(errors.id)}
                                value={ID}
                              />
                              {errors.id &&
                                <FormHelperText component={Box}>
                                  <Alert severity="error" className="custom-alert">
                                    {errors.id}
                                  </Alert>
                                </FormHelperText>
                              }
                            </FormControl>
                                
                          </div>

                          <div className='col-12 p-0'>
                            <LoadingButton
                              label={t("Continue")}
                              loading={isStartChecking}
                              classes='primary-btn py-2 px-4 mb-3'
                              handleClick={(e) => startVerification()} />
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <div className='col-12 p-0'>
                        <h4 className='mb-4'>{t("YourAccountIsVerified")}</h4>
                      </div>
                      <div className='col-12 p-0'>
                        <Link
                          to='/'
                          className='primary-btn py-2 px-4 mb-3 d-flex justify-content-center'
                          style={{ width: "fit-content" }}>
                          {t("BackToHomePage")}
                        </Link>
                      </div>
                    </>
                  )
                ) : (
                  <>
                    <div className='col-12 p-0'>
                      <h5 className='mb-4' style={{ width: "90%" }}>{t("YouAreRegularUser")}</h5>
                    </div>
                    <div className='col-12 p-0'>
                      <Link
                        to='/my-profile'
                        className='primary-btn py-2 px-4 mb-3'
                        style={{
                          marginBottom: "30px",
                          marginTop: "20px",
                          width: "fit-content",
                        }}>
                        {t("MyProfile2")}
                      </Link>
                    </div>
                  </>
                )
              ) : (
                <>
                  <div className='col-12 p-0'>
                    <h4 className='mb-4'>{t("PleaseLogin")}</h4>
                  </div>
                  <div className='col-12 p-0'>
                    <LoginPopup
                      setToken={setToken}
                      setUserId={setUserId}
                      setUserType={setUserType}
                      settext={t("Login")}
                      btnTyp='1'
                      path={"/verify"}
                    />
                  </div>
                </>
                )) : <CheckNafazOtp setIsSubmittingVerification={setIsSubmittingVerification} random={random} transId={transId} id={ID} />}
            </div>
          </div>
          <div
            className='col-12 d-none d-lg-flex col-lg-6 bg-cover py-5 py-lg-0'
            style={{
              background: `url(${publicUrl}assets/img/pattern.png) repeat`,
            }}>
            <div className='img-container'>
              <img
                src={publicUrl + "assets/img/others/others/2.png"}
                alt='whitelabel'
              />
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
}
